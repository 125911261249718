import updateStripeCustomerWrapper from "./../../utils/stripe/updateStripeCustomer";
import { proddev } from "../../PROD-DEV-TOGGLE";

var jsonpack = require("jsonpack/main");
const NodeRSA = require("node-rsa");
const zlib = require("zlib");

const putUserData = async (data, history) => {
  if (!data || !data?.data || data?.data === "" || !data?.userId) {
    // Exit early if data is invalid
    return;
  }

  // increment session count every time the user changes something
  /* const getDataSettingsFunction = data.data;
  const customerId =
    getDataSettingsFunction === null
      ? ""
      : getDataSettingsFunction.globalSettings.stripeId;
  await updateStripeCustomerWrapper(
    customerId,
    getDataSettingsFunction,
    "session"
  ); */

  var compressedData = jsonpack.pack(data.data);

  function escapeSpecialCharacters(str) {
    if (!str || typeof str !== "string") {
      return str;
    }

    var map = {
      "\b": "\\\\b",
      "\f": "\\\\f",
      "\n": "\\\\n",
      "\r": "\\\\r",
      "\t": "\\\\t",
      "\v": "\\\\v",
      "\\": "\\\\",
    };

    return str.replace(/[\b\f\n\r\t\v\\]/g, function (m) {
      return map[m];
    });
  }
  const escapeLiterals = escapeSpecialCharacters(compressedData);
  let newData = {
    data: {
      userId: data.userId,
      data: escapeLiterals, // compress the data
    },
  };

  const url =
    proddev === "prod"
      ? "https://opkt3gy2of.execute-api.us-west-1.amazonaws.com/prod/put-entries-multi"
      : "https://c5jfnnmixj.execute-api.us-west-1.amazonaws.com/default/put-entries-multi";

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newData),
    });

    // If the response is okay, exit the loop and return
    if (response.ok) {
      return;
    } else {
      history.push({
        pathname: "/Error-Found",
        state: {
          error: response.status,
        },
      });
      window.location.reload();
    }
  } catch (err) {
    history.push({
      pathname: "/Error-Found",
      state: {
        error: err.message,
      },
    });
    window.location.reload();
  }
};

export default putUserData;