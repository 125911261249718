const proxyURL =
  "https://tranquil-reaches-51121.herokuapp.com/createStripeSession";

export const createStripeSession = async (
  customerId,
  priceId,
  success_url,
  cancel_url,
  history
) => {
  const dataIn = {
    data: {
      customerId: customerId,
      priceId: priceId,
      success_url: success_url,
      cancel_url: cancel_url,
    },
  };
  const response = await fetch(proxyURL, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    headers: {
      Host: proxyURL,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "OPTIONS, DELETE, POST, GET, PATCH, PUT",
      "Access-Control-Allow-Headers": "Content-Type",
    },
    body: JSON.stringify(dataIn),
  }).catch((err) => {
    history.push({
      pathname: "/Error-Found",
      state: {
        error: err.message,
      },
    });
    window.location.reload();
  });
  return response.json(); // parses JSON response into native JavaScript objects
};
export default createStripeSession;