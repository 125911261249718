import $ from "jquery";
import store from "../../store";
import { setLoaderState } from "../../actions/actionCreators";
const NodeRSA = require("node-rsa");

const key = new NodeRSA();
key.importKey(
  `-----BEGIN PUBLIC KEY-----${process.env.REACT_APP_PUB_KEY}-----END PUBLIC KEY-----`,
  "pkcs8-public-pem"
);
const proxyURL =
  "https://tranquil-reaches-51121.herokuapp.com/initializeRobinhood";
const proxyURL2 =
  "https://tranquil-reaches-51121.herokuapp.com/getRobinhoodOrders";
const proxyURL3 =
  "https://tranquil-reaches-51121.herokuapp.com/getNewRobinhoodOrders";
const proxyURL4 =
  "https://tranquil-reaches-51121.herokuapp.com/getRobinhoodInstruments";
const proxyURL5 =
  "https://tranquil-reaches-51121.herokuapp.com/getRobinhoodOptionsInstruments";
const proxyURL6 =
  "https://tranquil-reaches-51121.herokuapp.com/getRobinhoodOptionsPosition";
const proxyURL7 =
  "https://tranquil-reaches-51121.herokuapp.com/getRobinhoodCryptoInstruments";

export const relayLinkRobinhood = async (
  email,
  password,
  mfaCode,
  retryCount = 0
) => {
  try {
    // encrypt their credentials to send to server
    const encryptedPassword = key.encrypt(JSON.stringify(password), "base64");
    const encryptedmfaCode = key.encrypt(JSON.stringify(mfaCode), "base64");

    const dataIn = {
      data: {
        email: email,
        password: encryptedPassword,
        mfaCode: encryptedmfaCode,
      },
    };
    const response = await fetch(proxyURL, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        Host: proxyURL,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods":
          "OPTIONS, DELETE, POST, GET, PATCH, PUT",
        "Access-Control-Allow-Headers": "Content-Type, Authorization",
      },
      body: JSON.stringify(dataIn),
    });
    return response && response.json(); // parses JSON response into native JavaScript objects
  } catch (error) {
    if (retryCount < 3) {
      // Retry after 5 seconds
      await new Promise((resolve) => setTimeout(resolve, 5000));
      return relayLinkRobinhood(email, password, mfaCode, retryCount + 1);
    } else {
      // Show error message notification
      const element = document.querySelector("#fetchingInsightsloader3");
      if (element) {
        element.style.opacity = 0.8;
        element.style.transform = "translate(0, 0)";
        element.style.transition = "opacity 1s, transform 1s";
      }
      localStorage.setItem(
        "autoiterationMessage",
        `An error has occurred. Refreshing...`
      );
      console.log("Failed after 3 retries:", error);
      store.dispatch(setLoaderState({ loading: false }));
      $(".autoimporterrormsg").css({ display: "block" });
      setTimeout(() => {
        $(".autoimporterrormsg").css({ display: "none" });
      }, 7000);
      // Hide error message notification after 5 seconds
      setTimeout(() => {
        const element = document.querySelector("#fetchingInsightsloader3");
        if (element) {
          element.style.opacity = 0;
          element.style.transform = "translate(100%, 0)";
          element.style.transition = "none";
        }
        localStorage.removeItem("autoiterationMessage");
      }, 5000);

      return null;
    }
  }
};

export const getAllRobinhoodTransactions = async (
  email,
  password,
  mfaCode,
  portfolio
) => {
  const relayData = await relayLinkRobinhood(email, password, mfaCode);
  if (
    !relayData ||
    (Object.keys(relayData?.initializedRobinhood).length === 0 &&
      relayData?.initializedRobinhood.constructor === Object)
  ) {
    $(".autoimporterrormsg").css({ display: "block" });
    setTimeout(() => {
      $(".autoimporterrormsg").css({ display: "none" });
    }, 7000);
    return { accessToken: "", robinhoodOrders: "error" };
  } else {
    const accessToken = relayData.initializedRobinhood.access_token;
    if (!accessToken) {
      return { accessToken: "", robinhoodOrders: "error" };
    }
    // access token encrypted server side
    //const encryptedToken = key.encrypt(accessToken, "base64");
    localStorage.setItem(`robinhoodToken${portfolio.name}`, accessToken);

    const getRobinhoodOrders = async (retryCount = 0) => {
      try {
        const dataIn = {
          data: {
            token: accessToken,
            assetClasses: portfolio.assetClasses,
          },
        };
        const response = await fetch(proxyURL2, {
          method: "POST",
          headers: {
            Host: proxyURL2,
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods":
              "OPTIONS, DELETE, POST, GET, PATCH, PUT",
            "Access-Control-Allow-Headers": "Content-Type, Authorization",
          },
          body: JSON.stringify(dataIn),
        });
        const responseData = response && response.json(); // parses JSON response into native JavaScript objects
        return responseData;
      } catch (error) {
        if (retryCount < 3) {
          // Retry after 5 seconds
          await new Promise((resolve) => setTimeout(resolve, 5000));
          return getRobinhoodOrders(retryCount + 1);
        } else {
          // Show error message notification
          const element = document.querySelector("#fetchingInsightsloader3");
          if (element) {
            element.style.opacity = 0.8;
            element.style.transform = "translate(0, 0)";
            element.style.transition = "opacity 1s, transform 1s";
          }
          localStorage.setItem(
            "autoiterationMessage",
            `An error has occurred. Refreshing...`
          );
          console.log("Failed after 3 retries:", error);
          store.dispatch(setLoaderState({ loading: false }));
          $(".autoimporterrormsg").css({ display: "block" });
          setTimeout(() => {
            $(".autoimporterrormsg").css({ display: "none" });
          }, 7000);
          // Hide error message notification after 5 seconds
          setTimeout(() => {
            const element = document.querySelector("#fetchingInsightsloader3");
            if (element) {
              element.style.opacity = 0;
              element.style.transform = "translate(100%, 0)";
              element.style.transition = "none";
            }
            localStorage.removeItem("autoiterationMessage");
          }, 5000);

          // You can return fallback data or handle the error gracefully here if applicable
          return null;
        }
      }
    };

    const robinhoodResponse = await getRobinhoodOrders();
    const robinhoodOrders =
      robinhoodResponse && robinhoodResponse.gotRobinhood.allorders;
    return { accessToken: accessToken, robinhoodOrders: robinhoodOrders };
  }
};

export const getNewRobinhoodTransactions = async (
  portfolio,
  ids,
  accessToken
) => {
  const getRobinhoodOrders = async (retryCount = 0) => {
    try {
      const dataIn = {
        data: {
          token: accessToken,
          assetClasses: portfolio.assetClasses,
          ids: ids,
        },
      };
      const response = await fetch(proxyURL3, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
          Host: proxyURL3,
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "OPTIONS, DELETE, POST, GET, PATCH, PUT",
          "Access-Control-Allow-Headers": "Content-Type, Authorization",
        },
        body: JSON.stringify(dataIn),
      });
      return response && response.json();
    } catch (err) {
      if (retryCount < 3) {
        // Retry after 5 seconds
        await new Promise((resolve) => setTimeout(resolve, 5000));
        return getRobinhoodOrders(retryCount + 1);
      } else {
        // Show error message notification
        const element = document.querySelector("#fetchingInsightsloader3");
        if (element) {
          element.style.opacity = 0.8;
          element.style.transform = "translate(0, 0)";
          element.style.transition = "opacity 1s, transform 1s";
        }
        localStorage.setItem(
          "autoiterationMessage",
          `An error has occurred. Refreshing...`
        );
        console.log("Failed after 3 retries:", err);
        store.dispatch(setLoaderState({ loading: false }));
        $(".autoimporterrormsg").css({ display: "block" });
        setTimeout(() => {
          $(".autoimporterrormsg").css({ display: "none" });
        }, 7000);
        // Hide error message notification after 5 seconds
        setTimeout(() => {
          const element = document.querySelector("#fetchingInsightsloader3");
          if (element) {
            element.style.opacity = 0;
            element.style.transform = "translate(100%, 0)";
            element.style.transition = "none";
          }
          localStorage.removeItem("autoiterationMessage");
        }, 5000);

        // You can return fallback data or handle the error gracefully here if applicable
        return null;
      }
    }
    // parses JSON response into native JavaScript objects
  };
  const robinhoodResponse = await getRobinhoodOrders();
  const robinhoodOrders =
    robinhoodResponse && robinhoodResponse.gotNewRobinhood.allorders;
  return { robinhoodOrders: robinhoodOrders };
};

export const getRobinhoodCryptoInstrument = async (retryCount = 0) => {
  try {
    const dataIn = {};
    const response = await fetch(proxyURL7, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        Host: proxyURL7,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods":
          "OPTIONS, DELETE, POST, GET, PATCH, PUT",
        "Access-Control-Allow-Headers": "Content-Type, Authorization",
      },
      body: JSON.stringify(dataIn),
    });
    return response && response.json(); // parses JSON response into native JavaScript objects
  } catch (err) {
    if (retryCount < 3) {
      // Retry after 5 seconds
      await new Promise((resolve) => setTimeout(resolve, 5000));
      return getRobinhoodCryptoInstrument(retryCount + 1);
    } else {
      // Show error message notification
      const element = document.querySelector("#fetchingInsightsloader3");
      if (element) {
        element.style.opacity = 0.8;
        element.style.transform = "translate(0, 0)";
        element.style.transition = "opacity 1s, transform 1s";
      }
      localStorage.setItem(
        "autoiterationMessage",
        `An error has occurred. Refreshing...`
      );
      console.log("Failed after 3 retries:", err);
      store.dispatch(setLoaderState({ loading: false }));
      $(".autoimporterrormsg").css({ display: "block" });
      setTimeout(() => {
        $(".autoimporterrormsg").css({ display: "none" });
      }, 7000);
      // Hide error message notification after 5 seconds
      setTimeout(() => {
        const element = document.querySelector("#fetchingInsightsloader3");
        if (element) {
          element.style.opacity = 0;
          element.style.transform = "translate(100%, 0)";
          element.style.transition = "none";
        }
        localStorage.removeItem("autoiterationMessage");
      }, 5000);

      // You can return fallback data or handle the error gracefully here if applicable
      return null;
    }
  }
};

export const getRobinhoodInstrument = async (url, retryCount = 0) => {
  try {
    const dataIn = {
      data: {
        url: url,
      },
    };
    const response = await fetch(proxyURL4, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        Host: proxyURL4,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods":
          "OPTIONS, DELETE, POST, GET, PATCH, PUT",
        "Access-Control-Allow-Headers": "Content-Type, Authorization",
      },
      body: JSON.stringify(dataIn),
    });
    return response && response.json(); // parses JSON response into native JavaScript objects
  } catch (err) {
    if (retryCount < 3) {
      // Retry after 5 seconds
      await new Promise((resolve) => setTimeout(resolve, 5000));
      return getRobinhoodInstrument(url, retryCount + 1);
    } else {
      // Show error message notification
      const element = document.querySelector("#fetchingInsightsloader3");
      if (element) {
        element.style.opacity = 0.8;
        element.style.transform = "translate(0, 0)";
        element.style.transition = "opacity 1s, transform 1s";
      }
      localStorage.setItem(
        "autoiterationMessage",
        `An error has occurred. Refreshing...`
      );
      console.log("Failed after 3 retries:", err);
      store.dispatch(setLoaderState({ loading: false }));
      $(".autoimporterrormsg").css({ display: "block" });
      setTimeout(() => {
        $(".autoimporterrormsg").css({ display: "none" });
      }, 7000);
      // Hide error message notification after 5 seconds
      setTimeout(() => {
        const element = document.querySelector("#fetchingInsightsloader3");
        if (element) {
          element.style.opacity = 0;
          element.style.transform = "translate(100%, 0)";
          element.style.transition = "none";
        }
        localStorage.removeItem("autoiterationMessage");
      }, 5000);

      // You can return fallback data or handle the error gracefully here if applicable
      return null;
    }
  }
};

export const getRobinhoodOptionInstrument = async (id, retryCount = 0) => {
  try {
    const dataIn = {
      data: {
        id: id,
      },
    };
    const response = await fetch(proxyURL5, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        Host: proxyURL5,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods":
          "OPTIONS, DELETE, POST, GET, PATCH, PUT",
        "Access-Control-Allow-Headers": "Content-Type, Authorization",
      },
      body: JSON.stringify(dataIn),
    });
    return response && response.json(); // parses JSON response into native JavaScript objects
  } catch (err) {
    if (retryCount < 3) {
      // Retry after 5 seconds
      await new Promise((resolve) => setTimeout(resolve, 5000));
      return getRobinhoodOptionInstrument(id, retryCount + 1);
    } else {
      // Show error message notification
      const element = document.querySelector("#fetchingInsightsloader3");
      if (element) {
        element.style.opacity = 0.8;
        element.style.transform = "translate(0, 0)";
        element.style.transition = "opacity 1s, transform 1s";
      }
      localStorage.setItem(
        "autoiterationMessage",
        `An error has occurred. Refreshing...`
      );
      console.log("Failed after 3 retries:", err);
      store.dispatch(setLoaderState({ loading: false }));
      $(".autoimporterrormsg").css({ display: "block" });
      setTimeout(() => {
        $(".autoimporterrormsg").css({ display: "none" });
      }, 7000);
      // Hide error message notification after 5 seconds
      setTimeout(() => {
        const element = document.querySelector("#fetchingInsightsloader3");
        if (element) {
          element.style.opacity = 0;
          element.style.transform = "translate(100%, 0)";
          element.style.transition = "none";
        }
        localStorage.removeItem("autoiterationMessage");
      }, 5000);

      // You can return fallback data or handle the error gracefully here if applicable
      return null;
    }
  }
};

export const getRobinhoodOptionPosition = async (
  id,
  accessToken,
  retryCount = 0
) => {
  try {
    const dataIn = {
      data: {
        id: id,
        token: accessToken,
      },
    };
    const response = await fetch(proxyURL6, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        Host: proxyURL6,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods":
          "OPTIONS, DELETE, POST, GET, PATCH, PUT",
        "Access-Control-Allow-Headers": "Content-Type, Authorization",
      },
      body: JSON.stringify(dataIn),
    });
    return response && response.json(); // parses JSON response into native JavaScript objects
  } catch (err) {
    if (retryCount < 3) {
      // Retry after 5 seconds
      await new Promise((resolve) => setTimeout(resolve, 5000));
      return getRobinhoodOptionPosition(id, accessToken, retryCount + 1);
    } else {
      // Show error message notification
      const element = document.querySelector("#fetchingInsightsloader3");
      if (element) {
        element.style.opacity = 0.8;
        element.style.transform = "translate(0, 0)";
        element.style.transition = "opacity 1s, transform 1s";
      }
      localStorage.setItem(
        "autoiterationMessage",
        `An error has occurred. Refreshing...`
      );
      console.log("Failed after 3 retries:", err);
      store.dispatch(setLoaderState({ loading: false }));
      $(".autoimporterrormsg").css({ display: "block" });
      setTimeout(() => {
        $(".autoimporterrormsg").css({ display: "none" });
      }, 7000);
      // Hide error message notification after 5 seconds
      setTimeout(() => {
        const element = document.querySelector("#fetchingInsightsloader3");
        if (element) {
          element.style.opacity = 0;
          element.style.transform = "translate(100%, 0)";
          element.style.transition = "none";
        }
        localStorage.removeItem("autoiterationMessage");
      }, 5000);

      // You can return fallback data or handle the error gracefully here if applicable
      return null;
    }
  }
};
export default getAllRobinhoodTransactions;
