import React, { useMemo, useContext, useRef } from "react";
import {
  Line,
  Bar,
  Bubble,
  Radar,
  Pie,
  Doughnut,
  Polar,
  Scatter,
} from "react-chartjs-2";
import { useHistory } from "react-router-dom";
import supportedCryptos from "../../arrays/isCurrencyCrypto";
import { getDateDifferencewSeconds } from "../../functions/getDateDifference";
import generateRandomColors from "../../functions/generateRandomColors";
import { DataContext } from "../../DataContext";
function findLargestArray(arrays) {
  let largestArray = [];
  if (arrays) {
    for (let i = 0; i < arrays.length; i++) {
      if (arrays[i]?.length > largestArray.length) {
        largestArray = arrays[i];
      }
    }
  }

  return largestArray;
}
function addColorsToArray(colors, selectedItemsLength) {
  const newColorArray = [];
  const colorsLength = colors.length;

  for (let i = 0; i < selectedItemsLength; i++) {
    const colorIndex = i % colorsLength;
    newColorArray.push(colors[colorIndex]);
  }

  return newColorArray;
}
let he = require("he");
const QuantumGraph = (props) => {
  const allGlobalData = useContext(DataContext);
  const allData = allGlobalData.allData;
  const portfolios = allData?.createdPortfolios;
  const {
    values,
    type,
    defaultSymbol,
    graphTitle,
    graph,
    whatToCompare,
    selectedItems,
    portfolio,
  } = props;

  const portfolioColor = portfolio.settings.portfolioColor;
  const colorINIT = useMemo(() => {
    return generateRandomColors(values.length);
  }, [values]);
  const portfolioColors = useMemo(() => {
    // Check if selectedItems is not null or undefined and has at least one item
    if (!selectedItems || selectedItems.length === 0) return [];
    const lastSelectedItemItems = selectedItems[selectedItems.length - 1].items;
    // Access the last item in the selectedItems array and map its items to portfolio colors
    return lastSelectedItemItems.map((item) => {
      const matchingPort = portfolios.find((port) => port.name === item.label);
      return matchingPort ? matchingPort.settings.portfolioColor : null;
    });
  }, []);
  const largestArray = values?.length && findLargestArray(values);
  const valueLength = type === "line" ? largestArray?.length : values?.length;
  const filteredTrades = largestArray?.map((value) => {
    return { entryId: value.id };
  });

  const randomColors = addColorsToArray(colorINIT, values.length); // dependencies that trigger recalculation of randomColors

  const lineColorMain =
    whatToCompare[whatToCompare.length - 1]?.label === "Portfolios"
      ? portfolioColors
      : randomColors; // Recalculate only if whatToCompare changes

  const supportedCryptosCheck = supportedCryptos.includes(defaultSymbol);
  const history = useHistory();
  const toComponentB2 = (row) => {
    // NEED TO REMOVE EDIT TRADE HERE TO ALLOW FOR EDIT TRADES
    // TO MERGE WITH INCOMING WEBSOCKET MSGS
    localStorage.removeItem("editTrade");

    localStorage.removeItem("editTradeId");
    history.push({
      pathname: "/Trade-Details",
      state: row,
    });
  };
  const getEntrybyIndex = (i) => {
    let entry = {};
    entry = filteredTrades.filter((_, index) => index === i);
    return entry;
  };

  const xaxisfunction = () => {
    let xaxisvalue = [];
    for (var iiy = 0, jjy = valueLength; iiy < jjy; iiy++) {
      xaxisvalue.push(iiy + 1);
    }
    return xaxisvalue;
  };
  //const lineColor = "#664bff" //purple

  const metricValues = () => {
    let metricValuesRet = [];
    if (type === "bar") {
      const allLastValues =
        values?.length &&
        values.map((valueArray) =>
          !valueArray[valueArray?.length - 1]
            ? 0
            : valueArray[valueArray?.length - 1].value
        );
      metricValuesRet.push({
        fill: false,
        lineTension: 0.1,
        backgroundColor: lineColorMain,
        borderColor: lineColorMain,
        borderCapStyle: "butt",
        borderDash: [],
        type: type,
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: lineColorMain,
        pointBackgroundColor: lineColorMain,
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: lineColorMain,
        pointHoverBorderColor: lineColorMain,
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        pointHitRadius: 10,
        data: allLastValues,
      });
    } else if (type === "line") {
      values.forEach((valueArray, index) => {
        metricValuesRet.push({
          label: graphTitle[index],
          fill: false,
          lineTension: 0.1,
          bezierCurve: true,
          backgroundColor: lineColorMain[index],
          //values.length > 1 ? lineColorMain[index] : portfolioColor,
          borderColor: lineColorMain[index],
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: lineColorMain[index],
          pointBackgroundColor: lineColorMain[index],
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: lineColorMain[index],
          pointHoverBorderColor: lineColorMain[index],
          pointHoverBorderWidth: 2,
          pointRadius: 0,
          pointHitRadius: 10,
          data: valueArray?.map((value) => value.value),
        });
      });
    }
    return metricValuesRet;
  };

  const metricVals = metricValues();
  const data = {
    labels: xaxisfunction(),
    datasets: metricVals,
  };

  const options = {
    tooltips: {
      mode: type === "line" && values.length > 1 ? "nearest" : "index",
      intersect: false,
      backgroundColor: "#14181e",
      titleFontSize: 14,
      titleFontColor: "#fbd665",
      bodyFontColor: "#fff7cb",
      bodyFontSize: 14,
      displayColors: false,
      enabled: true,
      callbacks: {
        title: (obj) => {
          const lastSelectedItemItems = selectedItems
            ? selectedItems[selectedItems.length - 1]?.items
            : [];
          const index = obj[0]?.index;
          const datasetIndex = obj[0]?.datasetIndex;
          let labelPrefix = "";
          if (lastSelectedItemItems) {
            const label =
              type === "line" && values.length > 1
                ? lastSelectedItemItems[datasetIndex]?.label
                : lastSelectedItemItems[index]?.label;
            labelPrefix = label ? `${label} ` : "";
          }
          const title = `${labelPrefix}${graphTitle}`;

          return title;
        },
        label: (tooltipItems) => {
          // Helper function for number formatting
          const formatNumber = (num, digits, suffix = "") =>
            parseFloat(num).toFixed(digits) + suffix;
          const dollarcreate = (tooltipItems) =>
            supportedCryptosCheck
              ? formatNumber(tooltipItems.yLabel, 6, " " + defaultSymbol)
              : he.decode(defaultSymbol) + formatNumber(tooltipItems.yLabel, 2);
          // Mapping graph types to their formatting logic
          const formatActions = {
            // Common format for these graphs
            totalprofitLossGraph: () => dollarcreate(tooltipItems),
            fullaccountbalanceGraph: () => dollarcreate(tooltipItems),
            avgPLGraph: () => dollarcreate(tooltipItems),
            volatilityGraph: () => dollarcreate(tooltipItems),
            standardDeviationGraph: () => dollarcreate(tooltipItems),
            maxDrawdownDollarGraph: () => dollarcreate(tooltipItems),
            maxRunUpDollarGraph: () => dollarcreate(tooltipItems),

            // Single format for a large group of graphs
            profitLossRatioGraph: () => formatNumber(tooltipItems.yLabel, 3),
            tradefuseGoldenRatioGraph: () =>
              formatNumber(tooltipItems.yLabel, 3),
            zScoreGraph: () => formatNumber(tooltipItems.yLabel, 3),
            statisticalZScoreGraph: () => formatNumber(tooltipItems.yLabel, 3),
            profitFactorGraph: () => formatNumber(tooltipItems.yLabel, 3),
            recoveryFactorGraph: () => formatNumber(tooltipItems.yLabel, 3),
            expectationGraph: () => formatNumber(tooltipItems.yLabel, 3),
            cpcGraph: () => formatNumber(tooltipItems.yLabel, 3),
            ulcerIndexGraph: () => formatNumber(tooltipItems.yLabel, 3),
            tradefuseIndexGraph: () => formatNumber(tooltipItems.yLabel, 3),
            avgPositionSizeGraph: () => formatNumber(tooltipItems.yLabel, 3),
            avgVolumeTradedGraph: () => formatNumber(tooltipItems.yLabel, 3),
            volumeTradedGraph: () => formatNumber(tooltipItems.yLabel, 3),
            totalVolumeTradedGraph: () => formatNumber(tooltipItems.yLabel, 3),
            arithmeticSharpeRatioGraph: () =>
              formatNumber(tooltipItems.yLabel, 3),
            arithmeticSortinoRatioGraph: () =>
              formatNumber(tooltipItems.yLabel, 3),
            geometricSharpeRatioGraph: () =>
              formatNumber(tooltipItems.yLabel, 3),
            geometricSortinoRatioGraph: () =>
              formatNumber(tooltipItems.yLabel, 3),
            KRatioGraph: () => formatNumber(tooltipItems.yLabel, 3),
            LRCorrelationGraph: () => formatNumber(tooltipItems.yLabel, 3),
            LRStandardErrorGraph: () => formatNumber(tooltipItems.yLabel, 3),

            // Percent format for these graphs
            probofRandomChanceGraph: () =>
              formatNumber(tooltipItems.yLabel, 3, "%"),
            avgEfficiencyGraph: () => formatNumber(tooltipItems.yLabel, 3, "%"),
            averageRiskPerTradeGraph: () =>
              formatNumber(tooltipItems.yLabel, 3, "%"),
            volatilityPercentGraph: () =>
              formatNumber(tooltipItems.yLabel, 3, "%"),
            standardDeviationPercentGraph: () =>
              formatNumber(tooltipItems.yLabel, 3, "%"),
            percentConfidenceGraph: () =>
              formatNumber(tooltipItems.yLabel, 3, "%"),
            winRateGraph: () => formatNumber(tooltipItems.yLabel, 3, "%"),
            lossRateGraph: () => formatNumber(tooltipItems.yLabel, 3, "%"),
            breakEvenRateGraph: () => formatNumber(tooltipItems.yLabel, 3, "%"),

            // Special cases with unique formatting
            avgHoldingTimeGraph: () =>
              getDateDifferencewSeconds(tooltipItems.yLabel),
            RMultipleRatioGraph: () =>
              formatNumber(tooltipItems.yLabel, 3, " R"),
            avgRiskRewardRatioGraph: () =>
              formatNumber(tooltipItems.yLabel, 3, ":1"),
          };

          // Execute the appropriate formatting function based on the graph type
          return (
            formatActions[graph] || (() => tooltipItems.yLabel.toString())
          )();
        },
      },
    },
    onHover: (event, chartElement) => {
      event.target.style.cursor =
        chartElement[0] && type === "line" ? "pointer" : "default";
    },
    onClick: (event, chartElement) => {
      if (!chartElement.length || type === "bar") {
      } else {
        toComponentB2(getEntrybyIndex(chartElement[0]._index)[0]);
      }
    },
    hover: {
      mode: type === "line" && values.length > 1 ? "nearest" : "index",
      intersect: false,
    },
    interaction: {
      mode: type === "line" && values.length > 1 ? "nearest" : "index",
      intersect: false,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: true,
          },
          ticks: {
            suggestedMin: 0,
            fontColor: "#98b5eb",
            callback: function (value) {
              // Helper function for number formatting
              const formatNumber = (num, digits, suffix = "") =>
                parseFloat(num).toFixed(digits) + suffix;
              const dollarcreate = (value) =>
                supportedCryptosCheck
                  ? formatNumber(value, 6, " " + defaultSymbol)
                  : he.decode(defaultSymbol) + formatNumber(value, 2);
              // Mapping graph types to their formatting logic
              const formatActions = {
                // Common format for these graphs
                totalprofitLossGraph: () => dollarcreate(value),
                fullaccountbalanceGraph: () => dollarcreate(value),
                avgPLGraph: () => dollarcreate(value),
                volatilityGraph: () => dollarcreate(value),
                standardDeviationGraph: () => dollarcreate(value),
                maxDrawdownDollarGraph: () => dollarcreate(value),
                maxRunUpDollarGraph: () => dollarcreate(value),

                // Single format for a large group of graphs
                profitLossRatioGraph: () => formatNumber(value, 3),
                tradefuseGoldenRatioGraph: () => formatNumber(value, 3),
                zScoreGraph: () => formatNumber(value, 3),
                statisticalZScoreGraph: () => formatNumber(value, 3),
                profitFactorGraph: () => formatNumber(value, 3),
                recoveryFactorGraph: () => formatNumber(value, 3),
                expectationGraph: () => formatNumber(value, 3),
                cpcGraph: () => formatNumber(value, 3),
                ulcerIndexGraph: () => formatNumber(value, 3),
                tradefuseIndexGraph: () => formatNumber(value, 3),
                avgVolumeTradedGraph: () => formatNumber(value, 3),
                volumeTradedGraph: () => formatNumber(value, 3),
                totalVolumeTradedGraph: () => formatNumber(value, 3),
                arithmeticSharpeRatioGraph: () => formatNumber(value, 3),
                arithmeticSortinoRatioGraph: () => formatNumber(value, 3),
                geometricSharpeRatioGraph: () => formatNumber(value, 3),
                geometricSortinoRatioGraph: () => formatNumber(value, 3),
                KRatioGraph: () => formatNumber(value, 3),
                LRCorrelationGraph: () => formatNumber(value, 3),
                LRStandardErrorGraph: () => formatNumber(value, 3),

                // Percent format for these graphs
                probofRandomChanceGraph: () => formatNumber(value, 3, "%"),
                avgEfficiencyGraph: () => formatNumber(value, 3, "%"),
                averageRiskPerTradeGraph: () => formatNumber(value, 3, "%"),
                volatilityPercentGraph: () => formatNumber(value, 3, "%"),
                standardDeviationPercentGraph: () =>
                  formatNumber(value, 3, "%"),
                percentConfidenceGraph: () => formatNumber(value, 3, "%"),
                winRateGraph: () => formatNumber(value, 3, "%"),
                lossRateGraph: () => formatNumber(value, 3, "%"),
                breakEvenRateGraph: () => formatNumber(value, 3, "%"),

                // Special cases with unique formatting
                avgHoldingTimeGraph: () => getDateDifferencewSeconds(value),
                RMultipleRatioGraph: () => formatNumber(value, 3, " R"),
                avgRiskRewardRatioGraph: () => formatNumber(value, 3, ":1"),
              };

              // Execute the appropriate formatting function based on the graph type
              return (formatActions[graph] || (() => value.toString()))();
            },
          },
          scaleLabel: {
            display: true,
            labelString: graphTitle,
            fontColor: "#fbd665",
            fontSize: 14,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            fontColor: "#deebf7",
            callback: function (index) {
              const lastSelectedItemItems =
                selectedItems && selectedItems[selectedItems.length - 1]?.items;
              return type === "line"
                ? index
                : lastSelectedItemItems
                ? lastSelectedItemItems[index - 1]?.label
                : index;
            },
          },
          scaleLabel: {
            display: true,
            fontColor: "#fbd665",
            labelString: "Entry Number",
            fontSize: 14,
          },
        },
      ],
    },
    maintainAspectRatio: false,
    responsive: true,
    responsiveAnimationDuration: 0,
    legend: {
      display: false,
    },
  };

  const graphToReturn =
    type === "line" ? (
      <Line
        data={data}
        options={options}
        className="ovallreportgraphactual"
        onElementsClick={(elems) => {
          if (!elems.length) {
          } else {
            toComponentB2(getEntrybyIndex(elems[0]._index)[0]);
          }
          // if required to build the URL, you can
          // get datasetIndex and value index from an `elem`:
          //console.log(elems[0]._datasetIndex + ", " + elems[0]._index);
          // and then redirect to the target page:
          //window.location = "https://example.com";
        }}
      />
    ) : (
      <Bar
        data={data}
        options={options}
        className="ovallreportgraphactual"
        /*         onElementsClick={(elems) => {
          if (!elems.length) {
          } else {
            toComponentB2(getEntrybyIndex(elems[0]._index)[0]);
          }
          // if required to build the URL, you can
          // get datasetIndex and value index from an `elem`:
          //console.log(elems[0]._datasetIndex + ", " + elems[0]._index);
          // and then redirect to the target page:
          //window.location = "https://example.com";
        }} */
      />
    );

  return (
    <div className="quantumgraph">
      <div className="quantumquerygraph-label" id="quantumquerygraph-label">
        {graphTitle}
      </div>
      <div className="overallreportgraphwrapper">{graphToReturn}</div>
    </div>
  );
};

export default QuantumGraph;
