import React from "react";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import PopoverStickOnHover from "./../../../components/PopoverStickOnHover";

require("typeface-roboto");

const StyledMainComponent = styled.div`
  position: relative;
  z-index: 100000;
  padding: 0;
  margin: 0;
  borderradius: 8px;
`;
const StyledMainComponent2 = styled.div`
  position: absolute;
  z-index: 100000;
  padding: 0;
  margin: 0;
  borderradius: 8px;
`;

const SideNavMainComponent = (props) => {
  const isLink = props.isLink;
  const isTop = props.isTop;
  const isClickable = props.isClickable;
  const history = useHistory();
  const location = useLocation();

  /*   var element = document.getElementById('foo');
  var positionInfo = element.getBoundingClientRect();
  var height = positionInfo.height;
  var width = positionInfo.width;  console.log(width) */
  return (
    location.pathname !== "/Error-Found" && (
      <div>
        {isLink ? (
          isTop ? (
            // Top component, and if it's a link (no dropdown)
            <StyledMainComponent>
              <PopoverStickOnHover
                placement="right"
                delay={350}
                keepOpen={false}
                xAdjust={0}
                yAdjust={4}
                component={<div style={{ color: "#fff7cb" }}>{props.text}</div>}
              >
                <div
                  onClick={(e) => {
                    history.push(props.link);
                  }}
                  className="sidenavstyledmain"
                  style={{
                    color: props.colour,
                    top: props.top,
                    cursor: "pointer",
                  }}
                >
                  {props.isFlatIcon ? (
                    <div className={props.iconclass}>
                      <img
                        className="dataanalysisicon"
                        src={props.icon}
                        alt="0"
                      />
                    </div>
                  ) : (
                    <div
                      className={props.iconclass}
                      style={{ left: props.left }}
                    >
                      <FontAwesomeIcon icon={props.icon} />
                    </div>
                  )}
                  <div className="sidenavtext">{props.text}</div>
                </div>
              </PopoverStickOnHover>
            </StyledMainComponent>
          ) : (
            // Not top component, but it's still a link (no dropdown)
            <StyledMainComponent2>
              <PopoverStickOnHover
                placement="right"
                delay={350}
                keepOpen={false}
                xAdjust={0}
                yAdjust={4}
                component={
                  <div
                    style={{
                      color: props.text === "Discord" ? "#7289DA" : "#fff7cb",
                    }}
                  >
                    {props.text}
                  </div>
                }
              >
                <a
                  href={props.link}
                  className="sidenavstyledmain"
                  style={{
                    color: props.colour,
                    top: props.top,
                    cursor: "pointer",
                  }}
                >
                  {props.isFlatIcon ? (
                    <div className={props.iconclass}>
                      <img
                        className="dataanalysisicon"
                        src={props.icon}
                        alt="0"
                      />
                    </div>
                  ) : (
                    <div className={props.iconclass}>
                      <FontAwesomeIcon icon={props.icon} />
                    </div>
                  )}
                  <div className="sidenavtext">{props.text}</div>
                </a>
              </PopoverStickOnHover>
            </StyledMainComponent2>
          )
        ) : isClickable ? (
          <StyledMainComponent>
            <div
              className="sidenavstyledmain forumbutton"
              style={{
                color: props.colour,
                top: props.top,
                cursor: "pointer",
              }}
            >
              <div className={props.iconclass}>
                <FontAwesomeIcon icon={props.icon} />
              </div>
              <div className="sidenavtext">{props.text}</div>
            </div>
          </StyledMainComponent>
        ) : (
          // these have dropdowns
          <StyledMainComponent>
            <div
              className={props.cname}
              style={{
                color: props.colour,
                top: props.top,
                left: props.left,
                cursor: "default",
                padding: 0,
                position: "absolute",
                display: "block",
                whiteSpace: "nowrap",
                overflow: "hidden",
                width: 50,
                transition: "none",
                backgroundColor: "#14181e",
                borderRadius: "8px",
                height: "48px",
                margin: "3px 3px 0px 3px",
              }}
            >
              {!props.insights ? (
                <FontAwesomeIcon
                  icon={props.icon}
                  className="sidenavicon"
                  style={{ marginLeft: props.marginLeft }}
                />
              ) : (
                <img className="dataanalysisicon" src={props.source} alt="0" />
              )}
              <div className="sidenavtext">{props.text}</div>
              <div>
                <div
                  className={props.name}
                  style={{
                    position: "absolute",
                    left: 160,
                    top: 6,
                    color: "#fff7cb",
                  }}
                >
                  <FontAwesomeIcon icon={faCaretRight} />
                </div>
              </div>
            </div>
          </StyledMainComponent>
        )}
      </div>
    )
  );
};

export default SideNavMainComponent;
