import {
  setLoaderState /* getFilteredList */,
} from "../actions/actionCreators";
import store from "../store";
import $ from "jquery";
import { initialSettingsStateNew } from "../pages/Settings/initialStates";
import moment from "moment-timezone";
import getUserData from "../utils/AWS/getS3UserObject";
import putUserData from "../utils/AWS/putS3UserObject";
import getFormattedDateTime from "../functions/getDateTimeStripeFormat";
import { v4 as uuidv4 } from "uuid";
import { useHistory } from "react-router-dom";

const proxyURL =
  "https://tranquil-reaches-51121.herokuapp.com/createStripeCustomer";
const proxyURL2 =
  "https://tranquil-reaches-51121.herokuapp.com/facebookAdHandler";

function getQueryParam(url, param) {
  const queryParams = new URLSearchParams(url.search);
  return queryParams.get(param);
}

function redirectToCleanURL() {
  // Get the current URL
  var currentURL = window.location.href;

  // Remove the parameters from the URL
  var cleanURL = currentURL.split("?")[0];

  // Redirect to the clean URL
  window.location.href = cleanURL;
}
//import filterData from "../functions/filterData";

//mport retry from "./retryOperation"
//import robinhoodWebApi from "../utils/brokers/robinhood";

//import updateSharedTrades from "./../utils/editSharedTrades";

localStorage.setItem("reloadFilters", "yes");
const additiontempcheck = localStorage.getItem("tempacceptTerms");
const additionmodalcheck = localStorage.getItem("showstartupwizard");

export const getDataandSettings = async (auth0user, loaderState, history) => {
  const Auth0User = auth0user;
  const S3InputData = {
    userId: Auth0User.sub,
  };

  // ---------------------------
  store.dispatch(setLoaderState({ loading: loaderState }));
  let intialDataPoint = "";
  let userData;
  let errorCatch = false;
  await Promise.all([getUserData(S3InputData, history)])
    .then((res) => {
      userData = res[0];
    })
    .catch((err) => {
      errorCatch = true;
      throw err;
    });

  const acceptedTerms = userData["data"]?.globalSettings.acceptedTerms;
  // ------------- Functions to run on sign up -------------
  if (
    !userData["data"] &&
    errorCatch !== true &&
    additiontempcheck !== "yes" &&
    additiontempcheck !== "no"
  ) {
    const element = document.getElementById("loadertext");

    /*     if (element) {
      setTimeout(() => {
        element.innerHTML = "Creating your account...";
      }, 0);
    } */

    // ------- Marketing APIs -------
    // send sign up data to marketing channels
    // check the url first
    // Get the current URL
    const currentUrl = new URL(window.location.href);

    // Extract the utm_source parameter
    const utmSource = getQueryParam(currentUrl, "utm_source");
    const utmCampaign = getQueryParam(currentUrl, "utm_campaign");

    async function getInfo() {
      try {
        // get user agent
        const userAgent = navigator.userAgent;

        // get IP address
        const response = await fetch("https://api.ipify.org/?format=json");
        const data = await response.json();
        const ipAddress = data.ip;
        return { ipAddress: ipAddress, userAgent: userAgent };
        // use the values of userAgent and ipAddress here
        // ...
      } catch (error) {
        console.error(error);
      }
    }

    const userAgentIpAddy = await getInfo();
    /* 
    // facebook
    const sendFacebookSignUpData = async () => {
      const dataIn = {
        data: [
          {
            event_name: "Lead",
            event_time: Date.now(),
            action_source: "website",
            em: [Auth0User.email],
            ph: [null],
            ct: [null],
            country: [null],
            db: [null],
            external_id: [stripeId],
            st: [null],
            ipAddress: userAgentIpAddy.ipAddress,
            userAgent: userAgentIpAddy.userAgent,
            signUpId: uuidv4(),
          },
        ],
      };
      const response = await fetch(proxyURL2, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
          Host: proxyURL2,
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "OPTIONS, DELETE, POST, GET, PATCH, PUT",
          "Access-Control-Allow-Headers": "Content-Type",
        },
        body: JSON.stringify(dataIn),
      }).catch((err) => {
        throw err;
      });
      return response.json(); // parses JSON response into native JavaScript objects
    };
    if (utmSource === "meta") {
      await sendFacebookSignUpData();
    } else if (utmSource === "twitter") {
    }
 */

    // =========== CREATE STRIPE CUSTOMER ===========
    /*     const dateTimeString = String(getFormattedDateTime());
    const relayCreateCustomer = async () => {
      const dataIn = {
        data: {
          "Last Login": dateTimeString,
          "Last Session": dateTimeString,
          Channel: utmSource || "",
          IPv4Address: userAgentIpAddy.ipAddress || "",
          UserAgent: userAgentIpAddy.userAgent || "",
          Campaign: utmCampaign || "",
          auth0id: Auth0User.sub,
        },
      };
      const response = await fetch(proxyURL, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
          Host: proxyURL,
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "OPTIONS, DELETE, POST, GET, PATCH, PUT",
          "Access-Control-Allow-Headers": "Content-Type",
        },
        body: JSON.stringify(dataIn),
      }).catch((err) => {
        throw err;
      });
      return response.json(); // parses JSON response into native JavaScript objects
    };
    localStorage.setItem("createdCustomer", "yes");

    const customer = await relayCreateCustomer();
    const stripeId = customer.id;
    // =============================================
    intialDataPoint = initialSettingsStateNew(stripeId);
    const S3Data = {
      data: intialDataPoint,
      userId: Auth0User.sub,
    }; */
    //stripeId && (await putUserData(S3Data)); // SET AWS DATA */

    /*     $(".roundperf").removeClass("round3perf");
    $(".roundperf").addClass("round2perf"); */
    //window.history.replaceState(null, "", "/");
    //localStorage.setItem("showstartupwizard", "yes");
    return "SendToStripe";
  } else {
    acceptedTerms && localStorage.setItem("tempacceptTerms", "yes");
    !acceptedTerms && localStorage.setItem("showstartupwizard", "yes");
  }

  // --------------------------
  if (userData["data"] === null || !userData["data"]) {
    return intialDataPoint;
  } else {
    /*     $(".roundperf").removeClass("round3perf");
    $(".roundperf").addClass("round2perf"); */
    return userData["data"];
  }
};

export default getDataandSettings;
