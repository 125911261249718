import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  useContext,
} from "react";
//import DeleteButton from "../../components/deleteButton";
import $ from "jquery";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord, faTwitter } from "@fortawesome/free-brands-svg-icons";
import "./styles.scss";
import createDatafromSettingsProps from "../../functions/createDatafromSettingsFunction";
import { DataContext } from "../../DataContext";
import putUserData from "../../utils/AWS/putS3UserObject";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import {
  proPriceIds,
  masterPriceIds,
  ultimatePriceIds,
} from "./../../utils/stripe/stripetiers";
const proxyURL =
  "https://tranquil-reaches-51121.herokuapp.com/updateStripeCustomer";

export const StartupWizard = (props) => {
  const dataInstance = useContext(DataContext);
  const { user /* isAuthenticated */ } = useAuth0();

  const userEmail = user.email;
  const userNAME = user.name;
  const history = useHistory();

  //const userName = userProfile.firstName + " " + userProfile.lastName;
  const customerId =
    dataInstance.allData === null
      ? ""
      : dataInstance.allData.globalSettings.stripeId;
  //const userNickname = userProfile.username;
  const customer = dataInstance?.customer;
  const customerPrice = customer?.priceId;
  const tierCapital = proPriceIds.includes(customerPrice)
    ? "Pro"
    : masterPriceIds.includes(customerPrice)
    ? "Master"
    : "Ultimate";
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalState, setmodalState] = useState("first");
  const [acceptedTerms, setacceptedTerms] = useState(false);
  const [loadDemo, setloadDemo] = useState(false);

  const [isdisabled, setisdisabled] = useState(true);
  const [email, setemail] = useState(userEmail);
  const [name] = useState(userNAME);

  const [emailSaved, setemailSaved] = useState(false);

  const allData = props.allData;
  const discordURl = "https://discord.gg/v8386xk";
  const twitterURl = "https://twitter.com/tradefuse";

  const firstUpdate = useRef(true);
  const wizardshow = localStorage.getItem("showstartupwizard");
  const signInType =
    user.sub.indexOf("google") !== -1
      ? "Google"
      : user.sub.indexOf("discord") !== -1
      ? "Discord"
      : user.sub.indexOf("auth0") !== -1
      ? "Auth0"
      : user.sub.indexOf("siwe") !== -1
      ? "Etheruem"
      : "";

  const handleLoadDemo = (e) => {
    setloadDemo(e.target.checked);
    if (e.target.checked === true) {
      localStorage.setItem("loadDemoData", "yes");
    } else {
      localStorage.removeItem("loadDemoData");
    }
  };
  const handleAcceptedTerms = (e) => {
    setacceptedTerms(e.target.checked);
    if (e.target.checked === true) {
      setisdisabled(false);
    } else {
      setisdisabled(true);
    }
  };
  useEffect(() => {
    if (firstUpdate.current) {
      if (wizardshow === "yes") {
        setIsOpen(true);
      }
    }
    return () => {
      firstUpdate.current = false;
    };
  }, [wizardshow]);
  const closeExitModal = () => {
    setIsOpen(false);
    localStorage.setItem("showstartupwizard", "no");
  };
  const acceptTerms = useCallback(async () => {
    localStorage.setItem("tempacceptTerms", "yes");
    let propsToChange = {};
    let globalpropsToChange = { acceptedTerms: true };
    const finalPush = createDatafromSettingsProps(
      allData.allData.chosenPortfolios,
      allData.allData.createdPortfolios,
      propsToChange,
      globalpropsToChange,
      allData.portfolio.settings,
      allData.allData.globalSettings,
      Number(allData.allData.version)
    );
    dataInstance.updateAllData(finalPush);
    try {
      const S3Data = {
        data: finalPush,
        userId: user.sub,
      };
      await putUserData(S3Data, history);
    } catch (err) {
      throw err;
    }
  }, [allData, dataInstance, user]);

  const saveEmail = useCallback(
    async (email, name) => {
      setemailSaved(true);
      const runUpdateStripeCustomer = async () => {
        const dataIn = {
          data: {
            customerId: customerId,
            email: email,
            name: name,
          },
        };
        const response = await fetch(proxyURL, {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          headers: {
            Host: proxyURL,
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods":
              "OPTIONS, DELETE, POST, GET, PATCH, PUT",
            "Access-Control-Allow-Headers": "Content-Type",
          },
          body: JSON.stringify(dataIn),
        })
          .then((res) => {})
          .catch((err) => {
            throw err;
          });
        return response?.json(); // parses JSON response into native JavaScript objects
      };
      customerId !== "" && (await runUpdateStripeCustomer());
    },
    [customerId, setemailSaved]
  );

  const wizardnext = useCallback(async () => {
    if (!acceptedTerms) {
    } else {
      if (modalState === "first") {
        // If the user signs in via google, discord, or auth0 db,
        // skip the email step
        await acceptTerms();
        if (
          signInType === "Google" ||
          signInType === "Auth0" ||
          signInType === "Discord"
        ) {
          setmodalState("third");
          const emailFromAccountInfo = user.email;
          !emailSaved && (await saveEmail(emailFromAccountInfo, name));
        } else {
          setmodalState("second");
        }
      } else if (modalState === "second") {
        setmodalState("third");
        !emailSaved && (await saveEmail(email, name));
      } else if (modalState === "third") {
        const dropdown = $(".portfolio-dropdown");

        //props.seteditMode("yes3");
        if (dropdown.css("display") === "flex") {
        } else {
          setTimeout(() => {
            dropdown.css({
              display: "flex",
            });
            $("#overlay").fadeIn(0);
            $("#noclick").fadeIn(0);
          }, 0);
        }
        closeExitModal();
      }
    }
  }, [
    modalState,
    acceptTerms,
    acceptedTerms,
    saveEmail,
    signInType,
    email,
    emailSaved,
    user,
    name,
  ]);
  const WizardModalname =
    modalState === "first"
      ? "WizardModal"
      : modalState === "second"
      ? "WizardModal2"
      : "WizardModal2";
  const WizardModalLabel =
    modalState === "first"
      ? "WELCOME"
      : modalState === "second"
      ? "EMAIL"
      : "CREATE A PORTFOLIO";
  const movecursor = {
    paddingLeft: "10px",
  };

  const emailRegex =
    // eslint-disable-next-line
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  const emailbuttonText = !emailRegex.test(email)
    ? "Skip For Now"
    : "Save Email";
  const emailbuttonClass = !emailRegex.test(email)
    ? "letsGoButton4"
    : "letsGoButton2";
  return (
    <Modal
      ariaHideApp={false}
      id={WizardModalname}
      className={WizardModalname}
      isOpen={modalIsOpen}
      closeTimeoutMS={200}
      contentLabel="Trade"
      overlayClassName="OverlayModal"
    >
      <h3 className="modalSymbolHeader6">{WizardModalLabel}</h3>

      {modalState === "first" ? (
        <div className="wizmessagewrapper">
          <div className="wizinnermessagewrapper">
            Welcome to your <b className="boldfirsttext">5-day free trial</b> of
            our {tierCapital} Tier✨{" "}
            <span>
              For the best experience possible, we recommend using a larger
              screen, preferably a{" "}
              <b className="firsttextn7">desktop or tablet.</b>
            </span>
          </div>
          {/*           <div className="wizinnermessagewrapper">
            This is the Beta release of our application, so we are looking for
            feedback and suggestions as we continue to add functionality.{" "}
          </div> */}
          <div className="wizinnermessagewrapper">
            Please come say hello and join our{" "}
            <button
              className="joinDiscordButton2"
              onClick={() => window.open(discordURl)}
            >
              Discord
            </button>{" "}
            community to speak directly with our team and connect with other
            traders.
          </div>
          <div className="wizinnermessagewrapper">
            We are dedicated to assisting you in unlocking your trading
            potential and achieving great success!
          </div>
          <div className="wizinnermessagewrapper">- The TradeFuse Team</div>
        </div>
      ) : modalState === "second" ? (
        <div className="wizmessagewrapper2">
          <div className="wizinnermessagewrapper">
            {`Collecting your email allows us to give quick app updates and personally reach out to you regarding support tickets.`}
          </div>
          <div className="wizinnermessagewrapper">
            You can enter it now or choose to stay anonymous.
          </div>
          <label className="wizselectionfieldsOPTION">
            Email
            <input
              name="emailField"
              style={movecursor}
              value={email}
              onChange={(e) => {
                setemail(e.target.value);
              }}
              className="text-input-boxes"
              autoComplete="off"
            />
          </label>
        </div>
      ) : (
        <div className="wizmessagewrapper2">
          <div className="wizinnermessagewrapper">
            {`Portfolios enable you to journal & track trades from your individual
            broker/exchange accounts. You can customize each portfolio's settings and
            even select multiple to view and compare your different broker/exchange
            accounts.`}
          </div>
          <div className="wizinnermessagewrapper">
            To import your trades, manually add trades, or to connect
            broker/exchange accounts for automated importing, you must have a
            least one portfolio created.
          </div>
          {/*           <div className="wizinnermessagewrapperline"></div>
           */}
          {/*           <div className="wizinnermessagewrapper">
            Would you like to add demo data to your first portfolio? This is for
            demonstration purposes and can be deleted later.
          </div>
          <label className="container checkssspv mae-tooltip-hover">
            <input
              type="checkbox"
              id="boxNoExitSlip"
              name="loadDemo"
              checked={loadDemo}
              onChange={(e) => handleLoadDemo(e)}
            />
            <span className="checkmark checkmark-col1-2"></span>
            <span className="adddemodata">Add Demo Data</span>
          </label> */}
        </div>
      )}
      {modalState === "first" && (
        <div className="firstwiztermstext">
          <div className="firstwiztermsheader">Terms of Use</div>
          <div style={{ margin: "16px 0px 0px 40px", padding: "0px" }}>
            <label className="container checkssspv mae-tooltip-hover">
              <input
                type="checkbox"
                id="boxNoExitSlip"
                name="hasExitSlippage"
                checked={acceptedTerms}
                onClick={(e) => handleAcceptedTerms(e)}
              />
              <span className="checkmark checkmark-col1-2"></span>
              <div className="iacceptthe">I accept the </div>
            </label>
            <a
              href="https://tradefuse.app/terms-and-conditions/"
              target="_blank"
              rel="noopener noreferrer"
              className="firstwiztermslink"
            >
              Terms and Conditions
            </a>
          </div>
        </div>
      )}
      <button
        name="letsGoButton"
        className={
          modalState === "first"
            ? "letsGoButton"
            : modalState === "second"
            ? emailbuttonClass
            : "letsGoButton3"
        }
        onClick={() => wizardnext()}
        disabled={isdisabled}
      >
        {modalState === "first"
          ? "Let's Go!"
          : modalState === "second"
          ? emailbuttonText
          : "Create a Portfolio"}
      </button>
      {modalState === "first" && (
        <div>
          <button
            name="joinTwitterButton"
            className="joinTwitterButton"
            onClick={() => window.open(twitterURl)}
          >
            <FontAwesomeIcon icon={faTwitter} />
            {/*             <div className="radio-label-buy-tooltip">
              <div className="radio-label-buy-tooltip-tip moveleftmore"></div>
              <div className="resetbuttontext">Follow Us on Twitter</div>
            </div> */}
          </button>
          <button
            name="joinDiscordButton"
            className="joinDiscordButton"
            onClick={() => window.open(discordURl)}
          >
            <FontAwesomeIcon icon={faDiscord} />
            {/*             <div className="radio-label-buy-tooltip">
              <div className="radio-label-buy-tooltip-tip moveleftmore"></div>
              <div className="resetbuttontext">Join Discord</div>
            </div> */}
          </button>
        </div>
      )}
    </Modal>
  );
};
export default StartupWizard;
