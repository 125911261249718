export async function getSP500Data() {
    const proxyURL =
      "https://tranquil-reaches-51121.herokuapp.com/getSP500Data";
  
    try {
      const dataIn = {};
      const response = await fetch(proxyURL, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
          Host: proxyURL,
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "OPTIONS, DELETE, POST, GET, PATCH, PUT",
          "Access-Control-Allow-Headers": "Content-Type, Authorization",
        },
        body: JSON.stringify(dataIn),
      }).catch((err) => {
        return [];
      });
      return response.json();
    } catch (error) {
      return [];
    }
  }
  export default getSP500Data;
  