const proxyURL =
  "https://tranquil-reaches-51121.herokuapp.com/handleOpenAImessage";
const proxyURL2 =
  "https://tranquil-reaches-51121.herokuapp.com/handleOpenAImessage3_5";
const proxyURLTUNED =
  "https://tranquil-reaches-51121.herokuapp.com/handleOpenAITUNEDrequests";
export const getOpenAIMessage = async (message, signal) => {
  const dataIn = {
    data: { message: message },
  };
  let attempt = 0;
  const maxAttempts = 1;
  while (attempt < maxAttempts) {
    try {
      const response = await fetch(proxyURL, {
        method: "POST",
        signal: signal,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "OPTIONS, DELETE, POST, GET, PATCH, PUT",
          "Access-Control-Allow-Headers": "Content-Type",
        },
        body: JSON.stringify(dataIn),
      });
      if (response.ok) {
        // If response is successful, no need to retry
        return await response.json();
      } else {
        // If server responded but not with a 2xx status, throw an error to retry
        throw new Error(`Server responded with status: ${response.status}`);
      }
    } catch (err) {
      attempt++;
      console.error(`Attempt ${attempt} failed. Error: ${err}`);
      if (attempt >= maxAttempts) {
        return "err";
      }
    }
  }
};

export const getOpenAIMessageMaster = async (message, signal) => {
  const dataIn = {
    data: { message: message },
  };
  let attempt = 0;
  const maxAttempts = 1;
  while (attempt < maxAttempts) {
    try {
      const response = await fetch(proxyURL2, {
        method: "POST",
        signal: signal,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "OPTIONS, DELETE, POST, GET, PATCH, PUT",
          "Access-Control-Allow-Headers": "Content-Type",
        },
        body: JSON.stringify(dataIn),
      });
      if (response.ok) {
        // If response is successful, no need to retry
        return await response.json();
      } else {
        // If server responded but not with a 2xx status, throw an error to retry
        throw new Error(`Server responded with status: ${response.status}`);
      }
    } catch (err) {
      attempt++;
      console.error(`Attempt ${attempt} failed. Error: ${err}`);
      if (attempt >= maxAttempts) {
        return "err";
      }
    }
  }
};

export const getOpenAIMessageTUNED = async (message, signal) => {
  const dataIn = {
    data: { message: message },
  };
  let attempt = 0;
  const maxAttempts = 1;
  while (attempt < maxAttempts) {
    try {
      const response = await fetch(proxyURLTUNED, {
        method: "POST",
        signal: signal,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "OPTIONS, DELETE, POST, GET, PATCH, PUT",
          "Access-Control-Allow-Headers": "Content-Type",
        },
        body: JSON.stringify(dataIn),
      });
      if (response.ok) {
        // If response is successful, no need to retry
        return await response.json();
      } else {
        // If server responded but not with a 2xx status, throw an error to retry
        throw new Error(`Server responded with status: ${response.status}`);
      }
    } catch (err) {
      attempt++;
      console.error(`Attempt ${attempt} failed. Error: ${err}`);
      if (attempt >= maxAttempts) {
        return "err";
      }
    }
  }
};

export default getOpenAIMessage;
