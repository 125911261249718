import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from "react";
import "./styles.scss";
import PopoverStickOnHover from "./../../components/PopoverStickOnHover";
import {
  getOpenAIMessage,
  getOpenAIMessageMaster,
  getOpenAIMessageTUNED,
} from "./../../utils/handleOpenAIRequest";
import { useAuth0 } from "@auth0/auth0-react";
import goldbrain from "./../../images/goldbrain.png";
import quantumCalculations from "../../calculations/quantumCalculations";
import {
  DayofWeekOptions,
  MonthOptions,
  HourRangeOptions2,
  VolumeRangeOptions,
} from "../../components/Right-Panel/Filter-Menu/FilterMenuDropdownOptions";
import { assetClasses } from "../Manual-Trade-Entry/DropDownOptions";
import filterTheTrades from "../Quantum-Query/filterTheTrades";
import QuantumGraph from "./quantumGraph";
import { useHistory } from "react-router-dom";
import createDatafromSettingsProps from "../../functions/createDatafromSettingsFunction";
import $ from "jquery";
import { DataContext } from "../../DataContext";
import putUserData from "../../utils/AWS/putS3UserObject";
import { v4 as uuidv4 } from "uuid";
import { stringify } from "querystring";
import useForceUpdate from "../../hooks/useForceUpdate";
import TradesTableComponent from "./TradesTableComponent";
import {
  fetchAIMetricsFunction,
  responseAIOuput,
  sampleAIInputs,
} from "./gptInputPrompts";
import {
  differenceInCalendarDays,
  differenceInCalendarWeeks,
  parseISO,
} from "date-fns"; // Make sure to install date-fns or similar library
const getTimeCategory = (date) => {
  const today = new Date();
  const conversationDate = new Date(date);

  const dayDifference = differenceInCalendarDays(today, conversationDate);
  const weekDifference = differenceInCalendarWeeks(today, conversationDate);

  if (dayDifference === 0) return "Today";
  if (dayDifference === 1) return "Yesterday";
  if (weekDifference === 0) return "Last Week";
  return "Earlier"; // Adjust according to needs
};
let he = require("he");

function isArrayEmpty(arr) {
  return arr.length === 0;
}
const sessions = [
  { label: "New York", value: "newyork" },
  { label: "London", value: "london" },
  { label: "Sydney", value: "sydney" },
  { label: "Tokyo", value: "tokyo" },
];
const outcomes = [
  { label: "Win", value: "Win" },
  { label: "Loss", value: "Loss" },
  { label: "Break-Even", value: "Break-Even" },
];

export const QuantumQueryComponent = (props) => {
  const [userInput, setuserInput] = useState();
  const [AIOutput, setAIOutput] = useState([]);
  const [AIOutputID, setAIOutputID] = useState("");
  const [fadeOutConversations, setFadeOutConversations] = useState({});
  const [originalMessage, setOriginalMessage] = useState(null);

  const allGlobalData = useContext(DataContext);
  const forceUpdate = useForceUpdate();
  const firstUpdate = useRef(true);
  const { user /* isAuthenticated */ } = useAuth0();
  const userAvatar = user.picture;
  const processedData = props.processedData;
  const portfolio = processedData?.portfolio;

  const entries = portfolio?.entries;
  const settings = portfolio?.settings;
  const precalcs = processedData.calculations;
  const calctype = settings.calculationType;
  const quantumConversationsPre = settings.quantumConversations;
  const dateFilterBy = settings.dateFilterBy;

  const riskFreeRate = props.riskFreeRate;
  const dropDownItems = processedData.filterLists;
  const allData = processedData.allData;
  const globalSettings = allData.globalSettings;
  const quantumCountPre = globalSettings.quantumCounter;
  const customer = allGlobalData?.customer;
  const portfolios = allData?.createdPortfolios;
  const subscription = customer?.subscription[0];
  const { current_period_end, status } = subscription; // Assuming single subscription
  const subscription_endPRE = globalSettings?.subscription_end;
  const [subscription_end, setsubscription_end] = useState(subscription_endPRE);
  const [quantumCount, setQuantumCount] = useState(
    0
    //quantumCountPre ? quantumCountPre : 0
  );
  const [editingMessageId, setEditingMessageId] = useState(null);
  const [editingMessageId2, setEditingMessageId2] = useState(null);

  const dropdownRef = useRef(null); // Create a ref for the dropdown container

  const tier = props.tier;
  const active = props.active;

  let showINIT = false;
  let maxCount = Number.MAX_SAFE_INTEGER;
  let AIversion = "";
  if (tier === "free") {
    showINIT = true;
  } else if (tier === "pro" && active) {
    showINIT = true;
  } else if (tier === "oldpro" && active) {
    showINIT = true;
  } else if (tier === "master" && active) {
    //maxCount = 75;
    showINIT = false;
    AIversion = "Master";
  } else if (tier === "ultimate" && active) {
    //maxCount = 250;
    showINIT = false;
    AIversion = "Ultimate";
  } else if (tier === "admin" && active) {
    //maxCount = Number.MAX_SAFE_INTEGER;
    showINIT = false;
  } else {
    showINIT = true;
  }
  const [quantumState, setquantumState] = useState(
    AIversion === "Ultimate" ? "QuantumQuery Advanced ✨" : "QuantumQuery"
  );
  const samplemsgs = [
    { header: "Metrics", prompt: "Whats the total pnl of my portfolio?" },
    {
      header: "Specifics",
      prompt:
        "How long did I usually hold trades taken on Wednesdays of last year?",
    },
    {
      header: "Graphs",
      prompt:
        "Provide graphs of the profit loss ratio and z-score for trades I've taken in the mornings (midnight - 8am).",
    },
    { header: "Assessments", prompt: "Which strategy am I trading best?" },
  ];
  const [sampleMessages, setsampleMessages] = useState();

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const stateOptions = ["QuantumQuery", "QuantumQuery Advanced ✨"];
  const toggleDropdown = () => setIsDropdownVisible(!isDropdownVisible);
  // Handle selecting an option for quantumquery
  const selectOption = (option) => {
    setquantumState(option);
    setIsDropdownVisible(false); // Optionally hide the dropdown after selection
  };
  /*   if (status === "trialing") {
    maxCount = 10; // for trial periods
  } */

  let allTrades = [];
  for (let i = 0, j = portfolios.length; i < j; i++) {
    if (portfolios[i].name === "Default") {
    } else {
      let entries = portfolios[i].entries;
      // add calculationType to each trade first
      for (let ii = 0, jj = entries.length; ii < jj; ii++) {
        allTrades.push(entries[ii]);
      }
    }
  }
  if (allTrades === null || allTrades.length === 1) {
  } else {
    allTrades.sort(function (a, b) {
      var c = new Date(
        !a.entry.endDateTime ? a.entry.startDateTime : a.entry.endDateTime
      );
      var d = new Date(
        !b.entry.endDateTime ? b.entry.startDateTime : b.entry.endDateTime
      );
      return c - d;
    });
  }

  const chosenPortfolios = allData.chosenPortfolios;
  const filteredPortfolios = chosenPortfolios.filter(
    (portfolio) => portfolio !== "Default"
  );
  const portfoliosDropdownItem = portfolios.reduce((dropdownItems, port) => {
    if (port.name !== "Default") {
      dropdownItems.push({ label: port.name, value: port.name });
    }
    return dropdownItems;
  }, []);
  // State to track the progress of messages being typed out

  /// LOCAL STORAGE VARIABLES
  var localQuantumConversations = JSON.parse(
    localStorage.getItem("quantumConversations")
  );
  const quantumConversationsINIT =
    localQuantumConversations !== null
      ? localQuantumConversations
      : quantumConversationsPre
      ? quantumConversationsPre
      : [];
  const [typedMessages, setTypedMessages] = useState({});
  const [quantumConversations, setquantumConversations] = useState(
    quantumConversationsINIT
  );
  const [callcounter, setcallcounter] = useState(0);
  const start = Date.now();
  const history = useHistory();

  const [finalStateTimeChanged, setfinalStateTimeChanged] = useState(start);
  var isDBRunning = localStorage.getItem("dbSetRunning");
  function escapeSpecialCharacters(str) {
    if (!str || typeof str !== "string") {
      return str;
    }

    var map = {
      "\b": "\\\\b",
      "\f": "\\\\f",
      "\n": "\\\\n",
      "\r": "\\\\r",
      "\t": "\\\\t",
      "\v": "\\\\v",
      "\\": "\\\\",
    };

    // First, remove all single and double quotes
    var noQuotesStr = str.replace(/['"]/g, "");

    // Then, escape the rest of the special characters
    return noQuotesStr.replace(/[\b\f\n\r\t\v\\]/g, function (m) {
      return map[m];
    });
  }

  // Function to check if the trial has ended or the subscription has renewed
  const checkSubscriptionStatus = useCallback(async () => {
    const customer = allGlobalData?.customer;
    const subscription = customer?.subscription[0];
    const subscription_endPRE = globalSettings?.subscription_end;
    const { current_period_end, status } = subscription; // Assuming single subscription
    setsubscription_end(current_period_end);
    if (
      !subscription_endPRE ||
      (current_period_end > subscription_endPRE &&
        (status === "active" || status === "trialing"))
    ) {
      // Reset count to 0 when subscription period restarts and is active
      setQuantumCount(0);
      await handleSubmit(quantumConversations, 0, current_period_end);
    }
  }, [quantumConversations, allGlobalData]);

  /*   useEffect(() => {
    checkSubscriptionStatus();

    // Delay the start of the interval by 1 second after component mounts, allows for waiting for allGlobalData to update first
    const timeout = setTimeout(() => {
      // Check subscription status periodically
      const interval = setInterval(checkSubscriptionStatus, 1000 * 60 * 0.5); // Check every 30s

      // Cleanup interval on component unmount
      return () => {
        clearInterval(interval);
      };
    }, 1000); // Delay interval start by 0.5s

    // Cleanup timeout on component unmount
    return () => clearTimeout(timeout);
  }, []); */

  const handleChange = useCallback(
    async (e) => {
      //const toggleState = quantumConversations;
      const currentTimez = Date.now();
      setfinalStateTimeChanged(currentTimez);
      $(window).bind("beforeunload", function () {
        return ">>>>>Before You Go<<<<<<<< \n Your custom message go here";
      });
      setcallcounter(1);
      localStorage.setItem("quantumConversations", JSON.stringify(e));
      setquantumConversations(e);
      forceUpdate();
    },
    [forceUpdate]
  );

  const handleSubmit = useCallback(
    async (
      quantumConversationsIn,
      /* quantumCounter,  */ current_period_end
    ) => {
      localStorage.setItem("dbSetRunning", "yes");
      var localQuantumConversations2 = JSON.parse(
        localStorage.getItem("quantumConversations")
      );

      let propsToChangei = {
        quantumConversations:
          localQuantumConversations2 || quantumConversationsIn,
      };

      let globalpropsToChange = {
        subscription_end: current_period_end || subscription_end,
      };
      const finalPush = createDatafromSettingsProps(
        allData.chosenPortfolios,
        allData.createdPortfolios,
        propsToChangei,
        globalpropsToChange,
        settings,
        allData.globalSettings,
        allData.version
      );
      // SET S3 DATA
      const S3Data = {
        data: finalPush,
        userId: user.sub,
      };
      const finalAwait = async () => {
        await putUserData(S3Data, history);
        allGlobalData.updateAllData(finalPush);
        $(window).unbind("beforeunload");
        localStorage.setItem("dbSetRunning", "no");
        setcallcounter(0);
      };
      await finalAwait();
    },
    [allData, settings, allGlobalData, user, subscription_end]
  );

  useEffect(() => {
    var isDBRunning = localStorage.getItem("dbSetRunning");

    // checks every 200ms for state updates
    // If the user does not change a state after 1.6 seconds, handleCallSetJSON gets called
    const userTimeAllowance = 1.6;
    let interval = setInterval(async () => {
      var a = Date.now();
      var b = new Date(finalStateTimeChanged);
      var difference = (a - b) / 1000;
      if (
        callcounter === 1 &&
        difference > userTimeAllowance &&
        isDBRunning === "no"
      ) {
        setcallcounter(0);
        await handleSubmit(quantumConversations);
      } else {
      }
    }, 200);

    if (firstUpdate.current) {
    }
    return () => {
      firstUpdate.current = false;
      clearInterval(interval);
    };
  }, [
    firstUpdate,
    callcounter,
    finalStateTimeChanged,
    handleSubmit,
    quantumConversations,
  ]);

  useEffect(() => {
    AIOutput.forEach((msg, index) => {
      if (msg.from === "gpt" && msg.type === "text") {
        // Skip typing effect for messages marked as part of a conversation
        if (msg.isConversation) {
          return;
        }

        // Immediately display messages that are flagged to bypass typing
        if (msg.bypassTyping) {
          //setTypedMessages({});
          return;
        }

        // Initialize typing effect for new messages
        if (!typedMessages.hasOwnProperty(index)) {
          // Immediately initialize the message as an empty string to avoid flashing the full message
          setTypedMessages((prev) => ({ ...prev, [index]: "" }));

          const fullMsg = msg.msg;
          let charIndex = 0;

          const typeNextChar = () => {
            if (charIndex < fullMsg.length) {
              setTypedMessages((prevTypedMessages) => {
                const newText = fullMsg.substring(0, charIndex + 1);
                return { ...prevTypedMessages, [index]: newText };
              });
              charIndex++;
            }
          };

          // Typing effect interval
          const timerId = setInterval(() => {
            typeNextChar();
            if (charIndex >= fullMsg.length) {
              clearInterval(timerId);
            }
          }, 10); // Adjust typing speed with the interval
        }
      }
    });
  }, [AIOutput, typedMessages]); // Watching both AIOutput and typedMessages

  useEffect(() => {
    // Using setTimeout to ensure this runs after React has updated the DOM
    setTimeout(() => {
      const newFadeOutConversations = {};
      const conversationElements = document.querySelectorAll(
        ".quantumquery-conversation"
      );

      conversationElements.forEach((element, index) => {
        const id = quantumConversations[index]?.id; // Assuming each conversation has a unique ID
        if (element.offsetHeight > 34) {
          newFadeOutConversations[id] = true;
        } else {
          newFadeOutConversations[id] = false;
        }
      });

      setFadeOutConversations(newFadeOutConversations);
    }, 0); // A delay of 0ms is enough to push this operation to the end of the event loop
  }, [quantumConversations]); // Dependency array to re-run the effect when quantumConversations changes
  // Effect for handling clicks outside of the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      // If the click is outside the dropdown, hide it
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownVisible(false);
      }
    };

    // Add when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup on unmount
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []); // Empty array ensures this runs once on mount and on unmount

  useEffect(() => {
    // Define the async function inside the useEffect
    const fetchData = async () => {
      const alteredInput3 = sampleAIInputs(
        entries,
        dropDownItems,
        portfoliosDropdownItem,
        sessions,
        DayofWeekOptions,
        MonthOptions,
        assetClasses,
        outcomes,
        HourRangeOptions2
      );

      let parsedObject;
      try {
        const completion = await getOpenAIMessageMaster(alteredInput3, signal);

        if (completion !== "err") {
          function removeFormatting(text) {
            // Replace the beginning "```json" with an empty string
            let result = text?.replace("```json", "");

            // Replace the ending "```" with an empty string
            result = result?.replace("```", "");

            return result;
          }

          // Example usage
          const formattedString = completion.content;

          const cleanString = removeFormatting(formattedString);
          parsedObject = cleanString && JSON.parse(cleanString);
        }
      } catch (error) {
        // Handle the error here
        console.error("Error parsing JSON:", error);
        // Set a default value or perform any other necessary actions
        parsedObject = null; // or any other appropriate default value
        controller.abort();
      }

      // Then, based on the object the AI generates, we fetch the calculations
      const { messages } = parsedObject || {};
      setsampleMessages(messages);
    };

    // Call the async function
    fetchData();

    // Cleanup on unmount
    return () => {
      // Your cleanup logic here
    };
  }, []); // Empty array ensures this runs once on mount and on unmount
  // Function to add animation with delay

  //console.log(calculations);
  const thedefaultSymbol = settings.currencySymbol;
  const controller = new AbortController();
  const signal = controller.signal;
  function autoGrowTextArea(event) {
    const textarea = event.target;

    // Define a minimum height (adjust as needed)
    const minHeight = 58; // Example starting height

    // Check if the textarea is empty
    if (textarea.value.trim() === "") {
      textarea.style.height = `${minHeight}px`;
    } else {
      // Temporarily shrink textarea to get a fresh scrollHeight measurement
      textarea.style.height = "1px";

      // Calculate the new height, ensuring it's not less than the minHeight
      // Add padding to the calculated scrollHeight to ensure space at the bottom
      const newHeight = Math.max(textarea.scrollHeight, minHeight);

      // Apply the new height
      textarea.style.height = `${newHeight}px`;
    }

    // Debugging logs
  }

  function filterAndTransformData(
    calculationsArray,
    graphArray,
    graphTypeArray,
    metricsArray
  ) {
    // Filter and prepare calculations based on the metrics array
    const calculations = calculationsArray.map((calculation) => {
      let filteredCalculation = {};
      if (calculation.filter) {
        filteredCalculation.filter = calculation.filter;
      }
      metricsArray.forEach((metric) => {
        if (calculation.hasOwnProperty(metric)) {
          filteredCalculation[metric] = calculation[metric];
        }
      });
      return filteredCalculation;
    });

    // Filter and prepare graph data based on the graph array, and add the graphtype
    // Initialize an empty graph array
    let graph = [];
    // Iterate over each calculation
    calculationsArray.forEach((calculation, index) => {
      // Iterate over each graph item in the graph array
      graphArray.forEach((graphItem) => {
        if (calculation.hasOwnProperty(graphItem)) {
          let graphCalculation = {}; // Create a new object for each graph item
          graphCalculation[graphItem] = calculation[graphItem]; // Add the graph data
          const graphIndex = graphArray.indexOf(graphItem);
          graphCalculation.graphtype = graphTypeArray[graphIndex];

          // Only push if graphCalculation is not empty
          if (
            Object.keys(graphCalculation).length > 0 &&
            calculationsArray.length > 0
          ) {
            graph.push(graphCalculation);
          }
        }
      });
    });
    return { calculations, graph };
  }

  const submitInitialMessage = useCallback(
    async (newAIOutput, userInput) => {
      // This functions creates the necessary metric/graphs objects with correct filters
      const alteredInput = fetchAIMetricsFunction(
        newAIOutput,
        entries,
        dropDownItems,
        filteredPortfolios,
        portfoliosDropdownItem,
        sessions,
        DayofWeekOptions,
        MonthOptions,
        assetClasses,
        outcomes,
        HourRangeOptions2
      );
      let parsedObject;
      try {
        const completion =
          AIversion === "Ultimate"
            ? quantumState === "QuantumQuery Advanced ✨"
              ? await getOpenAIMessage(alteredInput, signal)
              : await getOpenAIMessageTUNED(alteredInput, signal)
            : await getOpenAIMessageTUNED(alteredInput, signal);

        if (completion !== "err") {
          function removeFormatting(text) {
            // Replace the beginning "```json" with an empty string
            let result = text?.replace("```json", "");

            // Replace the ending "```" with an empty string
            result = result?.replace("```", "");

            return result;
          }

          // Example usage
          const formattedString = completion.content;

          const cleanString = removeFormatting(formattedString);
          parsedObject = cleanString && JSON.parse(cleanString);
        }
      } catch (error) {
        // Handle the error here
        console.error("Error parsing JSON:", error);
        // Set a default value or perform any other necessary actions
        parsedObject = null; // or any other appropriate default value
        controller.abort();
      }

      //Then, based on the object the AI generates, we fetch the calculations
      const {
        graph,
        graphType,
        graphTitle,
        metrics,
        whatToCompareProp,
        selectedItems,
        filterType,
        table,
      } = parsedObject || {};

      let calculationsArray = [];
      let newAIOutput2 = newAIOutput.slice();
      newAIOutput2 = newAIOutput2.filter(
        (output) => output.type !== "temporary"
      );
      // Generate calculations array basded on filters
      // Special handling based on filterType for the last filter
      if (filterType === "add") {
        // Apply all filters together
        const filteredTrades = filterTheTrades(
          entries,
          whatToCompareProp,
          selectedItems,
          dateFilterBy,
          allTrades
        );
        // Perform calculations on the filtered trades
        const calculations = quantumCalculations(
          filteredTrades,
          settings.calculationType,
          precalcs.start_Balance,
          riskFreeRate,
          settings.dateFilterBy
        );
        calculationsArray.push({
          ...calculations,
          filter: {
            whatToCompareProp: whatToCompareProp,
            selectedItem: selectedItems,
          },
        });

        // ----- Now, take response object, and generate graphs and text output describing the metrics. ------
        const result = filterAndTransformData(
          calculationsArray,
          graph,
          graphType,
          metrics
        );
        const graphsResult = result.graph;
        //const calculationsResult = result.calculations;

        if (graphsResult.length !== 0) {
          graphsResult.forEach((graphIn, index) => {
            newAIOutput2.push({
              props: {
                values: [graphIn[graph[index]]],
                type: graphIn.graphtype,
                id: uuidv4(),
                defaultSymbol: thedefaultSymbol,
                graphTitle: graphTitle[index],
                graph: graph[index],
                whatToCompare:
                  /*                   whatToCompareProp.filter((obj) => obj.label === "Portfolios")
                    .length > 0
                    ? whatToCompareProp
                    : [], //only return if portfolios */
                  whatToCompareProp,
                selectedItems:
                  /*                   whatToCompareProp.filter((obj) => obj.label === "Portfolios")
                    .length > 0
                    ? selectedItems
                    : [], //only return if portfolios */
                  selectedItems,
              },
              type: "graph",
              from: "gpt",
            });
          });
        }
      } else if (filterType === "compare") {
        // For 'compare', treat the last filter separately to generate multiple calculations
        //const baseFilters = whatToCompareProp.slice(0, -1);
        const baseSelectedItems = selectedItems.slice(0, -1);
        const lastFilterItems = selectedItems[selectedItems.length - 1].items;
        lastFilterItems.forEach((item) => {
          const currentSelectedItems = [
            ...baseSelectedItems,
            { items: [item] },
          ];
          const filteredTrades = filterTheTrades(
            entries,
            whatToCompareProp,
            currentSelectedItems,
            dateFilterBy,
            allTrades
          );
          // Perform calculations on the filtered trades
          const calculations = quantumCalculations(
            filteredTrades,
            settings.calculationType,
            precalcs.start_Balance,
            riskFreeRate,
            settings.dateFilterBy
          );
          calculationsArray.push({
            ...calculations,
            filter: {
              whatToCompareProp:
                whatToCompareProp[whatToCompareProp.length - 1].label,
              selectedItem: item.label,
            },
          });
        }); // ----- Now, take response object, and generate graphs and text output describing the metrics. ------
        const result2 = filterAndTransformData(
          calculationsArray,
          graph,
          graphType,
          metrics
        );
        const graphsResult2 = result2.graph;
        if (graphsResult2.length !== 0) {
          // Initialize an object to accumulate datasets for each graph type
          const datasetsByGraphType = {};

          graphsResult2.forEach((item) => {
            Object.keys(item).forEach((key) => {
              // Skip the graphtype key
              if (key === "graphtype") return;

              // Initialize the array for this graph type if it doesn't exist
              if (!datasetsByGraphType[key]) {
                datasetsByGraphType[key] = [];
              }
              // Append the dataset for the current graph
              datasetsByGraphType[key].push(item[key]);
            });
          });

          // Create the output structure
          Object.keys(datasetsByGraphType).forEach((key, index) => {
            // Find the graphtype; assuming default or finding from existing entries
            const graphtype =
              graphsResult2.find((item) => item[key])?.graphtype || "line";

            newAIOutput2.push({
              props: {
                values: datasetsByGraphType[key], // Array of datasets for this graph type
                type: graphtype,
                id: uuidv4(),
                defaultSymbol: thedefaultSymbol, // This needs to be defined elsewhere
                graphTitle: graphTitle[index], // Assuming graphTitle is defined elsewhere and matches index
                graph: graph[index], // Adjust based on actual need
                whatToCompare: whatToCompareProp,
                selectedItems: selectedItems,
              },
              type: "graph",
              from: "gpt",
            });
          });
        }
      }

      if (table) {
        const filteredTradesForTable = filterTheTrades(
          entries,
          whatToCompareProp,
          selectedItems,
          dateFilterBy,
          allTrades
        );
        const tradeIds = filteredTradesForTable.map((trade) => trade.entryId);
        newAIOutput2.push({
          type: "table",
          from: "gpt",
          id: uuidv4(),
          tradeIds: tradeIds,
          whatToCompare: whatToCompareProp,
          selectedItems: selectedItems,
        });
      }
      const result3 = filterAndTransformData(
        calculationsArray,
        graph,
        graphType,
        metrics
      );
      const calculationsResult3 = result3.calculations;
      // Then, here, we take the calculations, and tel the AI to respond to the initial user query, with the calculations, in a coherent manner.
      const alteredInput2 = responseAIOuput(
        newAIOutput,
        userInput,
        calculationsResult3,
        whatToCompareProp
      );
      let parsedObject2;

      try {
        const completion =
          AIversion === "Ultimate"
            ? quantumState === "QuantumQuery Advanced ✨"
              ? await getOpenAIMessage(alteredInput2, signal)
              : await getOpenAIMessageMaster(alteredInput2, signal)
            : await getOpenAIMessageMaster(alteredInput2, signal);
        if (completion !== "err") {
          function removeFormatting(text) {
            // Replace the beginning "```json" with an empty string
            let result = text.replace("```json", "");

            // Replace the ending "```" with an empty string
            result = result.replace("```", "");

            return result;
          }

          // Example usage
          const formattedString = completion.content;
          const cleanString = removeFormatting(formattedString);
          parsedObject2 = cleanString; //JSON.parse(formattedString);
        }
      } catch (error) {
        // Handle the error here
        console.error("Error parsing JSON:", error);
        // Set a default value or perform any other necessary actions
        parsedObject2 = null; // or any other appropriate default value
        controller.abort();
      }
      newAIOutput2.push({
        msg: escapeSpecialCharacters(String(parsedObject2).trim()),
        id: uuidv4(),
        type: "text",
        from: "gpt",
      });

      // Assuming AIOutputID can be blank, which means no conversation is currently selected
      const countUserFrom = newAIOutput2.filter(
        (item) => item.from === "user"
      ).length;
      let quantumConversationsNew = quantumConversations?.slice();
      const currentConvoIndex = quantumConversationsNew.findIndex(
        (convo) => convo.id === AIOutputID
      );
      const shouldCreateNewConversation = countUserFrom <= 1;

      const convoId = uuidv4();
      if (shouldCreateNewConversation) {
        const dateNOW = new Date();
        // If there's one user message or AIOutputID is blank, create a new conversation
        const newConversation = {
          id: convoId,
          convo: newAIOutput2,
          lastChanged: dateNOW,
        };
        quantumConversationsNew.unshift(newConversation);
      } else {
        if (AIOutputID !== "") {
          // If there's more than one user message and a conversation is selected, update that conversation
          quantumConversationsNew[currentConvoIndex].convo = newAIOutput2;
        } else {
          // If AIOutputID is blank but there's more than one user message, update the first conversation in the list
          quantumConversationsNew[0].convo = newAIOutput2;
        }
      }

      // Proceed with state update and count increment as before
      setquantumConversations(quantumConversationsNew);
      //const newCount = quantumCount < maxCount ? quantumCount + 1 : maxCount;
      /*       const newCount = quantumCount + 1;
      setQuantumCount(newCount); */
      localStorage.setItem(
        "quantumConversations",
        JSON.stringify(quantumConversationsNew)
      ); // ensure this called!!
      await handleSubmit(quantumConversationsNew);
      setAIOutput(newAIOutput2);
      //setAIOutputID(convoId);
    },
    [
      userInput,
      AIOutput,
      quantumConversations,
      quantumCount,
      AIOutputID,
      quantumState,
    ]
  );
  document.addEventListener("DOMContentLoaded", () => {
    const dots = document.querySelectorAll(".dot");
    const colors = ["#007bff", "#28a745", "#dc3545"]; // Example custom colors

    dots.forEach((dot, index) => {
      dot.style.backgroundColor = colors[index % colors.length];
    });
  });
  const processMessage = (message, id) => {
    // Split the message by '\\n' to handle the line breaks
    return message.split("\\\\n").map((part, index, array) => (
      <div messageId={id}>
        {part}
        {index < array.length - 1 && <br />}
      </div>
    ));
  };
  const processMessage2 = (message) => {
    // Split the message by '\\n' to handle the line breaks
    return message.split("\\\\n").map((part, index, array) => (
      <div>
        {part}
        {index < array.length - 1 && <br />}
      </div>
    ));
  };
  // Add this function to the onChange handler
  const elemAiOutput = useCallback(() => {
    return AIOutput.map((msg, id) => {
      const isFromGPT = msg.from === "gpt";
      const isTable = msg.type === "table";
      const isTemporary = msg.type === "temporary";
      const isGraph = msg.type === "graph";
      const msgId = JSON.stringify(uuidv4());

      let messageToShow;
      let showGraph = false;
      let isEditing = editingMessageId === id; // Check if the current message is being edited

      if (isFromGPT && isGraph) {
        // Handling for 'graph' type messages
        const Component = QuantumGraph;
        const props = msg.props;
        messageToShow = <Component {...props} portfolio={portfolio} />;
        showGraph = true;
      } else if (isFromGPT && isTable) {
        // Handling for 'graph' type messages
        messageToShow = (
          <TradesTableComponent
            userData={processedData}
            tradeIds={msg.tradeIds}
            tier={tier}
            active={active}
            dashboardwidth={"100%"}
            innerwidth={"300px"}
          />
        );
        showGraph = true;
      } else if (isFromGPT && isTemporary) {
        // Handling for 'graph' type messages
        messageToShow = (
          <div className="ellipsis-container">
            <div className="dot" style={{ animationDelay: "0s" }}></div>
            <div className="dot" style={{ animationDelay: "0.5s" }}></div>
            <div className="dot" style={{ animationDelay: "1s" }}></div>
          </div>
        );
      } else if (!isFromGPT && isEditing) {
        const msgText = processMessage(
          typedMessages.hasOwnProperty(id) ? typedMessages[id] : msg.msg,
          msgId
        );
        messageToShow = (
          <div
            contentEditable={true}
            suppressContentEditableWarning={true}
            ref={(element) => {
              if (element) {
                element.focus(); // Focus on the element

                // Move the cursor to the end of the text
                const range = document.createRange(); // Create a range
                range.selectNodeContents(element); // Select the entire contents of the element
                range.collapse(false); // Collapse the range to the end point. false means end of the content
                const selection = window.getSelection(); // Get the selection object
                selection.removeAllRanges(); // Remove all ranges from the selection
                selection.addRange(range); // Add the new range
              }
            }}
            onBlur={(e) => {
              // Placeholder for onBlur handler, such as updating state or handling save
              //console.log("Edited content:", e.target.innerText);
              // You would replace the console.log with your state update or save logic
            }}
            style={{
              outline: "none",
              cursor: "text",
              minWidth: "20px", // Ensure div is clickable when empty
            }}
          >
            {msgText}
          </div>
        );
      } else {
        const msgText = processMessage(
          typedMessages.hasOwnProperty(id) ? typedMessages[id] : msg.msg,
          msgId
        );
        // Default message display
        messageToShow = msgText;
      }
      // Edit button (for user messages and when not showing a graph)
      let editButton = !isFromGPT && !showGraph && !isEditing && (
        <button
          className="edit-MESSAGE-btn"
          onClick={
            () => {
              setEditingMessageId(id); // If the message is from the user and is being edited
              /*               let ogMessage = typedMessages.hasOwnProperty(id)
                ? typedMessages[id]
                : msg.msg;
              console.log(ogMessage);
              //ogMessage = ogMessage.replace(/\\\\n/g, '\n');

              // Update the message in `AIOutput` or another state to make it editable:
              const updatedAIOutput = AIOutput.map((msg, currentIndex) => {
                if (currentIndex === id) {
                  // Assuming `id` is the index. If it's not, adjust accordingly.
                  return { ...msg, msg: ogMessage, isEditing: true }; // Add `isEditing` flag or use another method to indicate editing.
                }
                return msg;
              });
              console.log(updatedAIOutput);

              setAIOutput(updatedAIOutput); // Update the state to trigger re-render. */

              //const element = document.querySelector(`[messageId="${id}"]`);
              //const originalContent = element.getAttribute("data-original");
              //setOriginalMessage(originalContent);
            } // Implement this according to your application's logic
          }
        >
          ✎
        </button>
      );

      // Submit and Cancel buttons
      let submitCancelButtons = isEditing && (
        <div className="qqsubmit-btn-wrapper">
          <button
            className="qqsubmit-btn"
            onClick={async () => {
              // Ensure there is a conversation being edited
              if (editingMessageId !== null) {
                // Find the conversation by AIOutputID
                let conversationIndex;
                if (AIOutputID !== "") {
                  // If there's more than one user message and a conversation is selected, update that conversation
                  conversationIndex = quantumConversations.findIndex(
                    (conversationObj) => conversationObj.id === AIOutputID
                  );
                } else {
                  // If AIOutputID is blank but there's more than one user message, update the first conversation in the list
                  conversationIndex = 0;
                }
                if (conversationIndex !== -1) {
                  // Clone the conversations array to avoid direct state mutation
                  const updatedConversations = [...quantumConversations];

                  let conversationToUpdate =
                    updatedConversations[conversationIndex].convo;

                  // Assuming the new content is retrieved similarly to the previous example
                  /*                   const ogMessage = JSON.parse(
                    JSON.stringify(
                      typedMessages.hasOwnProperty(id)
                        ? typedMessages[id]
                        : msg.msg
                    )
                  ); */

                  const editableElement = document.querySelector(
                    "[contentEditable=true]"
                  );
                  let processedContent = "";
                  let isFirstNode = true;

                  // Helper function to handle text concatenation
                  const appendText = (text, addNewLine = false) => {
                    if (addNewLine && !isFirstNode) {
                      processedContent += "\\n"; // Add '\\n' for new lines except before the first line
                    }
                    processedContent += text;
                    isFirstNode = false; // Update flag after adding text
                  };

                  editableElement.childNodes.forEach((node) => {
                    if (node.nodeType === Node.TEXT_NODE) {
                      // Directly append text node content
                      appendText(node.textContent);
                    } else if (node.nodeType === Node.ELEMENT_NODE) {
                      // For DIV elements, need to treat them as new lines except possibly the first
                      const innerText = node.innerText; // Captures the text content, ignoring any child <br> or other tags
                      const directBr = node.innerHTML.trim() === "<br>"; // Check if the DIV directly wraps a BR

                      if (directBr) {
                        appendText("", true); // Add only a new line for DIVs that directly wrap a BR
                      } else if (node.innerHTML.includes("<br>")) {
                        // For DIVs containing BR, split innerHTML by BR to handle multiple lines within a single DIV
                        node.innerHTML
                          .split("<br>")
                          .forEach((part, index, array) => {
                            if (part.trim()) {
                              // Ignore purely whitespace parts
                              const partText = part.replace(/<[^>]*>/g, ""); // Remove any HTML tags from the part
                              appendText(partText, index > 0); // Add new line before parts after the first
                            } else if (index < array.length - 1) {
                              // For empty parts before the last, add new lines
                              appendText("", true);
                            }
                          });
                      } else {
                        // For DIVs without BR, simply append their innerText
                        appendText(innerText, !isFirstNode); // Add new line if not first node
                      }
                    }
                  });

                  // When trimming the conversation and updating AIOutput
                  conversationToUpdate = conversationToUpdate
                    .slice(0, editingMessageId)
                    .map((msg) => ({
                      ...msg,
                      bypassTyping: true, // Add this flag to bypass typing for these messages
                    }));
                  setAIOutput(conversationToUpdate);
                  submitAImessage(processedContent);
                }

                // Reset editing state
                setEditingMessageId(null);
              }
            }}
          >
            Submit
          </button>
          <button
            className="qqcancel-btn"
            onClick={() => setEditingMessageId(null)}
            style={{ marginLeft: "16px" }}
          >
            Cancel
          </button>
        </div>
      );

      return (
        <div className="quantumquery-output-msg-wrapper" key={id}>
          <div id={isFromGPT ? "profile-icon3" : "profile-icon32"}>
            <img
              alt=""
              src={isFromGPT ? goldbrain : userAvatar}
              style={{
                overflow: "hidden",
                maxWidth: "100%",
                maxHeight: "100%",
                borderRadius: 100,
                zIndex: 1000000000,
                margin: isFromGPT ? "1px 14px 0px 0px" : "0px 14px 0px 0px",
              }}
            />
          </div>{" "}
          <div className="quantumquery-output-msg-wrapper-sub">
            <div
              className={
                !showGraph
                  ? isFromGPT
                    ? "quantumquery-output-msg"
                    : "quantumquery-output-msg2"
                  : isTable
                  ? "quantumquery-output-table"
                  : "quantumquery-output-graph"
              }
            >
              {messageToShow}
            </div>
            <div>{editButton}</div>
            <div>{submitCancelButtons}</div>
          </div>
        </div>
      );
    });
  }, [
    AIOutput,
    typedMessages,
    editingMessageId,
    originalMessage,
    editingMessageId2,
  ]);

  function scrollToBottom() {
    // Find the element
    var element = document.querySelector(".quantumquery-output-wrapper");
    if (element) {
      // Scroll to the bottom
      element.scrollTop = element.scrollHeight;
    }
  }
  useEffect(() => {
    scrollToBottom();
  }, [AIOutput, typedMessages]);

  const submitAImessage = (userInput) => {
    setuserInput("");
    // First update
    setTimeout(() => {
      setAIOutput((currentAIOutput) => {
        const newAIOutput = currentAIOutput.slice(); // Copy current state
        newAIOutput.push({
          msg: escapeSpecialCharacters(String(userInput).trim()),
          type: "text",
          from: "user",
        });
        submitInitialMessage(newAIOutput, userInput);
        return newAIOutput; // Return the updated state
      });
    }, 1);

    // Second update
    setTimeout(() => {
      setAIOutput((currentAIOutput) => {
        const newAIOutput = currentAIOutput.slice(); // Copy current state
        newAIOutput.push({ msg: "", type: "temporary", from: "gpt" });
        return newAIOutput; // Return the updated state
      });
    }, Math.random() * (1300 - 300) + 300);
  };

  const conversationsOutput = useCallback(() => {
    // Initialize a map to keep track of whether the time category divs have been added
    const addedCategories = {
      Today: false,
      Yesterday: false,
      "Last Week": false,
      Earlier: false,
    };
    const messages =
      !showINIT &&
      quantumConversations?.map((conversationObj, index) => {
        const conversation = conversationObj.convo;
        const conversationId = conversationObj.id;
        const conversationlastChanged = conversationObj.lastChanged;

        const shouldFadeOut = fadeOutConversations[conversationId];
        const timeCategory = getTimeCategory(conversationlastChanged);

        // Check if the category div needs to be added
        let categoryDiv = null;
        if (!addedCategories[timeCategory]) {
          categoryDiv = (
            <div
              key={`${timeCategory}-${index}`}
              className="quantumquery-conversation-msg-timeheader"
            >
              {timeCategory}
            </div>
          );
          addedCategories[timeCategory] = true;
        }
        const processedContent = processMessage2(conversation[0].msg);
        const conversationComponent = (
          <div
            className={`quantumquery-conversation ${
              shouldFadeOut ? "fade-out-effect" : ""
            }`}
            onClick={() => {
              const conversationWithMarker = conversation.map((msg) => ({
                ...msg,
                isConversation: true,
              }));
              if (conversationId !== AIOutputID) {
                setAIOutput(conversationWithMarker);
                setTypedMessages({});
                setAIOutputID(conversationId);
                setEditingMessageId(null);
              }

              //controller.abort();
              // Assuming `controller.abort()` is defined elsewhere
            }}
            key={uuidv4()}
          >
            <div className="quantumquery-conversation-msg">
              {processedContent}
            </div>
            <button
              className="qconversationxtop"
              onClick={async (e) => {
                e.stopPropagation();
                let quantumConversationsPre = quantumConversations.slice();
                const returnDo = quantumConversationsPre.filter(
                  (convo) => convo.id !== conversationId
                );
                if (AIOutputID === conversationId) {
                  setAIOutput([]);
                  setTypedMessages({});
                  setAIOutputID("");
                }
                setquantumConversations(returnDo);
                localStorage.setItem(
                  "quantumConversations",
                  JSON.stringify(returnDo)
                );
                handleChange(returnDo);
              }}
            ></button>
          </div>
        );

        return categoryDiv
          ? [categoryDiv, conversationComponent]
          : conversationComponent;
      });
    return messages;
  }, [
    quantumConversations,
    fadeOutConversations,
    showINIT,
    setAIOutput,
    setquantumConversations,
    setAIOutputID,
    handleChange,
  ]);

  return (
    <div className="quantumquery-superwrapper">
      <div className="messages-output-wrapper">
        <button
          className="messages-output-newchatbutton"
          onClick={() => {
            setAIOutput([]);
            setAIOutputID("");
            setTypedMessages({});
            setEditingMessageId(null);

            controller.abort();
          }}
          disabled={showINIT || quantumCount >= maxCount}
        >
          New Trading Query
        </button>
        <div className="quantumquery-conversation-wrapper">
          {conversationsOutput()}
        </div>
      </div>{" "}
      {
        <div className="modeltoggler">
          <div
            className={
              quantumState === "QuantumQuery Advanced ✨"
                ? "modeltogglerclickelem2"
                : "modeltogglerclickelem"
            }
            onClick={toggleDropdown}
            style={{ cursor: "pointer", marginBottom: "10px" }}
          >
            {
              <div
                className={
                  quantumState === "QuantumQuery Advanced ✨"
                    ? "quantumquerygradient"
                    : ""
                }
              >
                {quantumState}
              </div>
            }
          </div>
          {isDropdownVisible && (
            <div ref={dropdownRef} className="modeltogglerclickdropdown">
              {stateOptions.map((option, index) => (
                <div
                  key={index}
                  className="modeltogglerclickdropdownoption"
                  onClick={() =>
                    option === "QuantumQuery Advanced ✨"
                      ? AIversion === "Master"
                        ? props.setshowUpgradeModal({
                            show: true,
                            featureSelect: "QuantumQuery Advanced",
                            blur: false,
                            tierText: "ultimate",
                          })
                        : selectOption(option)
                      : selectOption(option)
                  }
                  style={{
                    padding:
                      quantumState === "QuantumQuery Advanced ✨"
                        ? "5px 2px 5px 5px"
                        : "5px",
                    cursor: "pointer",
                  }}
                >
                  <div
                    className={
                      option === "QuantumQuery Advanced ✨"
                        ? "quantumquerygradient"
                        : ""
                    }
                  >
                    {option}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      }
      {/*       {quantumCount >= maxCount && (
        <div className="quantumquery-outofmsgs-wrapper">
          Looks like you've run out of queries this subscription period.{" "}
          <div>
            <span
              className="quantumquery-outofmsgs-button"
              onClick={() =>
                history.push({
                  pathname: "/Manage-Account",
                  state: {
                    account: "none",
                    subscription: "block",
                    actions: "none",
                  },
                })
              }
            >
              Upgrade your plan
            </span>{" "}
            to get more queries.
          </div>
        </div>
      )} */}
      <div className="quantumquery-wrapper">
        <div
          className={
            quantumCount >= maxCount
              ? "quantumquery-output-wrapper quantumquery-output-wrapper-lessheight"
              : "quantumquery-output-wrapper"
          }
        >
          {elemAiOutput()}
        </div>{" "}
        {AIOutput.length === 0 && (
          <div className={"quantumQueryHowcanIhelp"}>
            <img
              alt=""
              src={goldbrain}
              style={{
                overflow: "hidden",
                maxWidth: "60px",
                maxHeight: "60px",
                borderRadius: 100,
                zIndex: 1000000000,
                margin: "0px 14px 0px calc(50% - 28px)",
              }}
            />
            What would you like to know about your trading today?
          </div>
        )}
        {AIOutput.length === 0 && sampleMessages && (
          <div className="quantumQueryQuestionsWrapper">
            {sampleMessages.map((message, index) => {
              const delays = [0, 0.2, 0.4, 0.6]; // These are your animation delays
              const delay = delays[index % 4];

              return (
                <div
                  key={index}
                  className={`quantumQueryQuestions hidden fadeInUp`}
                  onClick={() => {
                    !showINIT &&
                      quantumCount < maxCount &&
                      submitAImessage(message.prompt);
                  }}
                  style={{ animationDelay: `${delay}s` }} // Apply delay here
                >
                  <div className="quantumQueryQuestionsTitle">
                    {message.header}
                  </div>
                  {message.prompt}
                </div>
              );
            })}
          </div>
        )}
        <textarea
          id="userInputTextArea"
          value={userInput}
          onChange={(e) => {
            setuserInput(e.target.value);
            autoGrowTextArea(e);
          }}
          className="text-input-boxes-quantumquery"
          autoComplete="off"
          placeholder="Ask anything about your trades..."
        ></textarea>
        <PopoverStickOnHover
          component={
            <div
              style={{
                color: "#fff7cb",
                padding: "2px",
                textAlign: "left",
                whiteSpace: "pre-wrap",
              }}
            >
              {"Submit message"}
            </div>
          }
          placement="bottom"
          onMouseEnter={() => {}}
          xAdjust={17}
          yAdjust={0}
          delay={250}
          keepOpen={true}
        >
          <button
            id="quantum-submit"
            disabled={showINIT || quantumCount >= maxCount}
            onClick={() => {
              submitAImessage(userInput);
              document.getElementById("userInputTextArea").style.height =
                "58px"; // This line changes the textarea height
            }}
          >
            {"🡒"}
          </button>
        </PopoverStickOnHover>
        <div className="quantumquery-disclaimer-wrapper">
          {
            "Disclaimer: QuantumQuery provides information for educational purposes only and is not intended as financial advice. We do not guarantee the accuracy or completeness of the information. Use of QuantumQuery is at your own risk. For financial advice, please consult a qualified professional."
          }
        </div>
      </div>
      {/*       <div className={"quantumCount"}>
        <div className="quantumCountinwrapper">
          <span className="quantumCountin">{`${quantumCount}`}</span>/
          <span className="quantumCountin2">{maxCount}</span>
        </div>
        <div className="quantumCountin3">
          {"Requests used"}
          <PopoverStickOnHover
            component={
              <div
                style={{
                  color: "#fff7cb",
                  padding: "2px",
                  width: 196,
                  textAlign: "left",
                }}
              >
                You have used {quantumCount}{" "}
                {quantumCount === 1 ? "request" : "requests"} this subscription
                period.
              </div>
            }
            placement="bottom-end"
            onMouseEnter={() => {}}
            delay={300}
            setClass="accountNumberSuperWrapper"
            xAdjust={27}
            yAdjust={0}
            keepOpen={false}
          >
            <span className="tooptip-i-style7">&#x1D48A;</span>
          </PopoverStickOnHover>
        </div>
      </div> */}
    </div>
  );
};
export default QuantumQueryComponent;
