import React, { useEffect, useState, useRef, useContext } from "react";
import PropTypes from "prop-types";
import {
  ContentWrapper,
  StyledSideNav,
  Background,
  SuperContainer,
} from "../PageTemplate/TemplateDivs.js";
import NavigationBar from "../Navigation-Bar/NavigationBar";
import "./PageTemplateStyles.scss";
import globaljquery from "../../functions/GLOBALJQUERY";
import RightPanelFunction from "./../Right-Panel/index";
import FullPageLoader from "./../FullPageLoader/FullPageLoader";
import { useHistory, useLocation } from "react-router-dom";
import { useAuth0, Auth0Context } from "@auth0/auth0-react";

import {
  faQuestionCircle,
  faPowerOff,
  faCheckCircle,
  faCaretLeft,
  faCaretRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//import Portfolios from "./../Portfolios/portfoliosModal"

require("typeface-roboto");

const PageTemplateBlank = (props) => {
  const title = props.title;
  const [height, setHeight] = useState([window.innerHeight]);
  const [width, setWidth] = useState([window.innerWidth]);
  const location = useLocation();
  const history = useHistory();
  const auth0Instance = useContext(Auth0Context);

  let _isMounted = useRef(true);
  useEffect(() => {
    const handleWindowResize = () => {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", () => {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
    });
    if (_isMounted.current === true) {
      globaljquery(history, location);
      document.getElementById("overlay").style.display = "none";
      document.getElementById("noclick").style.display = "none";
    }
    return () => {
      window.removeEventListener("resize", handleWindowResize);
      _isMounted.current = false;
    };
  }, []);
  return (
    <div className="appsupercontainer">
      <div id="overlay"></div>
      <div id="noclick"></div>
      <Background />
      <NavigationBar tabName={title} />
      {
        <div className="logout-dropdown">
          <button
            onClick={async () => {
              //window.location.reload();
              localStorage.clear();
              auth0Instance.logout({
                returnTo: window.location.origin,
              });
            }}
            className="logout-link"
          >
            <div className="small-icons-OC5">
              <FontAwesomeIcon icon={faPowerOff} />
            </div>
            Log Out
          </button>
        </div>
      }
      <ContentWrapper id="main">
        {location.pathname !== "/Error-Found" && (
          <StyledSideNav className="sidebaronly" id="sidebaronly" />
        )}
        <div
          className="contentLB2"
          style={{
            height:
              width < 1680 || height < 900
                ? width < 1680 && height < 900
                  ? `calc(100% - 51px)`
                  : `calc(100% - 62px)`
                : width > 481
                ? height > 800
                  ? height - 62
                  : height - 50
                : height - 50,
          }}
        >
          {location.pathname === "/Error-Found" && (
            <div className="errorOccuredTopwrapper">
              <div className="errorOccuredTopTotle">
                Whoops, looks like an error occured.
              </div>
              <div className="errorOccuredTopTotle2">
                We've reported this error, but your journal is still fine. Try
                going back to the{" "}
                <span
                  id="howtogetdydxcredentialslink2"
                  onClick={() => {
                    window.location.href = "/Dashboard";
                  }}
                >
                  Dashboard
                </span>
                . If you run into more issues, please{" "}
                <a
                  href="https://tradefuse.app/support"
                  target="_blank"
                  rel="noopener noreferrer"
                  id="howtogetdydxcredentialslink"
                >
                  reach out
                </a>
                .
              </div>
              <img
                className="errorOccuredTopimage"
                src="https://i.imgur.com/qIufhof.png"
              />
              <button
                className="errorOccuredTopButton"
                onClick={() => (window.location.href = "/Dashboard")}
              >
                Back to Dashboard
              </button>
            </div>
          )}
        </div>
        {/* <RightPanelFunction tabName={title} /> */}
      </ContentWrapper>
      <div className="confirmedactionwrapper">
        <div value="Submit" className="confirmedactionbutton"></div>
      </div>
      {props.showloader && <FullPageLoader />}
    </div>
  );
};

PageTemplateBlank.propTypes = {
  title: PropTypes.string.isRequired,
};

export default PageTemplateBlank;
