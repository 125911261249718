import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import getDateDifference from "../../functions/getDateDifference";

export const PromoCodeElement = () => {
  const dateString = "09/06/2023 23:59:59"; // Type in the date here
  const [datePart, timePart] = dateString.split(" ");
  const [month, day, year] = datePart.split("/");
  const [hours, minutes, seconds] = timePart.split(":");
  const datenow = new Date();

  const [dateNow, setdateNow] = useState(datenow);
  const trialExpiredDate = new Date(
    year,
    month - 1,
    day,
    hours,
    minutes,
    seconds
  );
  useEffect(() => {
    // checks every 300ms for state updates
    // If the user does not change a state after 1.6 seconds, handleCallSetJSON gets called
    let interval = setInterval(async () => {
      const datenowinner = new Date();
      setdateNow(datenowinner);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });
  return (
    <div
      id="promocodeelement"
      /*               onClick={() =>
    history.push({
      pathname: "/Manage-Account",
      state: {
        account: "none",
        subscription: "block",
        actions: "none",
      },
    })
  } */
    >
      <div id="promocodeelementsmalltext2">
        {getDateDifference(dateNow, trialExpiredDate)}
      </div>
      <div id="promocodeelementsmalltext">✨Labor Day Sale✨</div>
      <div id="promocodeelementmaintext">30% OFF Code: LABORDAY1</div>
    </div>
  );
};
export default PromoCodeElement;
